import { Component, Host, OnDestroy, Optional } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Route, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { mainRoutes } from '../../../main/main.routes';


@Component({
    selector: 'app-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnDestroy {

    private readonly routes: Route[] = [];
    private alive: Subject<any>;

    constructor(
        @Host() @Optional() private sidenav: MatSidenav,
        private router: Router
    ) {
        this.alive = new Subject();
        this.routes = mainRoutes[0].children;
    }

    public getRoutes(): Route[] {
        return this.routes;
    }

    ngOnDestroy(): void {
        this.alive.next();
        this.alive.complete();
    }
}
