import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Provider } from 'src/app/models/provider.model';

@Component({
  selector: 'app-confirm-delete-provider-dialog',
  templateUrl: './confirm-delete-provider-dialog.component.html',
  styleUrls: ['./confirm-delete-provider-dialog.component.scss']
})
export class ConfirmDeleteProviderDialogComponent implements OnInit {

  constructor(
    private translate: TranslateService,
    public dialogRef: MatDialogRef<ConfirmDeleteProviderDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public provider: Provider
  ) { }

  ngOnInit(): void {
  }

  getName(): string {
    const providerTypeName = this.translate.instant('entities.provider_types.' + this.provider.provider_type.code);

    return `${this.provider.company} (${providerTypeName})`;
  }
}
