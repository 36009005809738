<div mat-dialog-title>
  <h3>{{(isNewUser? 'users.create.title' : 'users.update.title') | translate}}</h3>
  <mat-icon color="primary" mat-dialog-close>close</mat-icon>
</div>

<mat-dialog-content>
  <form class="create-user-form mt-3" (ngSubmit)="submitUser(userForm)" [formGroup]="userForm" novalidate>
    <mat-form-field>
      <mat-label>{{'entities.users.last_name' | translate}}</mat-label>
      <input matInput formControlName="last_name">
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{'entities.users.first_name' | translate}}</mat-label>
      <input matInput formControlName="first_name">
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{'entities.users.email' | translate}}</mat-label>
      <input matInput formControlName="email">
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{'entities.users.igg' | translate}}</mat-label>
      <input matInput formControlName="igg" maxlength="8">
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{'entities.users.role' | translate}}</mat-label>
      <mat-select formControlName="role_id">
        <mat-option *ngFor="let role of roles" [value]="role.id">
          {{role.code}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</mat-dialog-content>

<mat-dialog-actions class="buttons-bar">
  <button mat-flat-button mat-dialog-close>{{'general.buttons.cancel' | translate}}</button>
  <button mat-flat-button color="primary" (click)="submitUser(userForm)">
    {{'general.buttons.validate' | translate}}
  </button>
</mat-dialog-actions>
