import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { BasicCodedObject } from 'src/app/models/basic-coded-object.model';
import { ConfirmDeleteObjectDialogComponent } from '../dialogs/confirm-delete-object-dialog/confirm-delete-object-dialog.component';
import { BasicObjectService } from '../../../services/basic-object.service';

@Component({
  selector: 'app-types-tab',
  templateUrl: './types-tab.component.html',
  styleUrls: ['./types-tab.component.scss']
})
export class TypesTabComponent implements OnInit {

  typesForm: FormGroup;

  constructor(
    private basicObjectService: BasicObjectService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private translate: TranslateService
  ) {
  }

  get typesArray(): FormArray {
    return this.typesForm.get('types') as FormArray;
  }

  ngOnInit(): void {
    this.initForm();

    this.basicObjectService.getTypes().subscribe((types: BasicCodedObject[]) => {
      this.initForm(types);
    });
  }

  switchTypeEdit(type: FormGroup): void {
    if (type.enabled) {
      type.controls.code.reset(type.controls.initialCode.value);
      type.disable();
      return;
    };

    type.enable();
  }

  saveType(type: FormGroup): void {
    const id = type.value.id;

    const httpRequest = id ? this.basicObjectService.updateType(id, type.value)
      : this.basicObjectService.createType(type.value);

    httpRequest.subscribe(() => {
      type.disable();
      this.snackBar.open(this.translate.instant('general.messages.data_saved'));
    });
  }

  deleteType(type: FormGroup): void {
    if (!type.controls.id.value) {
      this.removeType(type);
      return;
    }

    const dialogRef = this.dialog.open(ConfirmDeleteObjectDialogComponent, {
      data: type.value
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res?.confirm) {
        this.basicObjectService.deleteType(type.controls.id.value).subscribe(() => {
          this.removeType(type);
          this.snackBar.open(this.translate.instant('general.messages.data_deleted'));
        });
      }
    });
  }

  removeType(type: FormGroup): void {
    this.typesArray.removeAt(this.typesArray.controls.indexOf(type));
  }

  addType(type?: BasicCodedObject): void {
    const typeGroup = this.fb.group({
      id: type?.id,
      initialCode: type?.code,
      code: [type?.code, Validators.required]
    });

    typeGroup.disable();
    this.typesArray.push(typeGroup);
  }

  private initForm(types?: BasicCodedObject[]): void {
    this.typesForm = this.fb.group({
      types: this.fb.array([])
    });

    if (types) {
      this.initTypesFormArray(types);
    }
  }

  private initTypesFormArray(types: BasicCodedObject[]): void {
    types.forEach((type: BasicCodedObject) => {
      this.addType(type);
    });
  }
}
