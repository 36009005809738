import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRouteSnapshot, CanActivate, Route, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from '@ff/auth';
import { UserService } from '@ff/user';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { User } from '../models/user.model';
import { authRoutesNames } from '../modules/auth/auth.routes.names';
import { coreRoutesNames } from '../modules/core/core.routes.names';

@Injectable({
  providedIn: 'root'
})
export class AppAdminGuard implements CanActivate {

  constructor(
    public authService: AuthService,
    private userService: UserService,
    private router: Router,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
  ) {
  }

  canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
    return this.canAccess();
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canAccess();
  }

  private canAccess(): any {
    try {
      const isLoggedIn = this.authService.isLoggedIn().getValue();

      if (isLoggedIn) {
        const user = new User(this.userService.getUser().getValue());
        if (user.isAdmin()) {
          return true;
        } else {
          this.gotoLogin();
        }
      } else {
        this.gotoLogin();
      }
    } catch (error) {
      this.gotoLogin();
    }
  }

  private gotoLogin(): void {
    this.authService.logout();
    this.snackBar.open(this.translate.instant('general.messages.errors.forbidden'));
    this.router.navigate([coreRoutesNames.AUTH + '/' + authRoutesNames.LOGIN]);
  }
}



