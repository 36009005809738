import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmDeleteProviderDialogComponent } from '../../providers/confirm-delete-provider-dialog/confirm-delete-provider-dialog.component';
import { Contact } from './../../../../../models/contact.model';

@Component({
  selector: 'app-confirm-delete-contact-dialog',
  templateUrl: './confirm-delete-contact-dialog.component.html',
  styleUrls: ['./confirm-delete-contact-dialog.component.scss']
})
export class ConfirmDeleteContactDialogComponent implements OnInit {

  constructor(
    private translate: TranslateService,
    public dialogRef: MatDialogRef<ConfirmDeleteProviderDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public contact: Contact
  ) { }

  ngOnInit(): void {
  }

  getName(): string {
    return `${this.contact.name}`;
  }
}
