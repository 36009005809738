import { TranslateService } from '@ngx-translate/core';
import { UtilServiceService, loaderHide, loaderShow } from './../../../shared/services/util-service.service';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { LoaderService } from '@ff/loader';
import * as moment from 'moment';
import { absoluteRoutesNames } from 'src/app/modules/shared/absolute.routes';
import { Incident } from './../../../../models/incident.model';
import { DatetimeHelperService } from './../../../shared/services/datetime-helper.service';
import { IncidentService } from './../../services/incident.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-incidents',
  templateUrl: './incidents.component.html',
  styleUrls: ['./incidents.component.scss']
})
export class IncidentsComponent implements OnInit, AfterViewInit {

  @ViewChild('incidentsTableContainer') incidentsTableContainer: ElementRef;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  incidentForm: FormGroup;

  incidentsDataSource: MatTableDataSource<Incident> = new MatTableDataSource([]);
  incidentsDisplayedColumns = ['declaration_date', 'creation_date', 'code_implant', 'site_name', 'user', 'role', 'type', 'severity', 'status', 'see'];

  incidentsPaginationItem = {
    total: 0,
    next_page: null,
    previous_page: null
  };

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private incidentService: IncidentService,
    private datetimeHelper: DatetimeHelperService,
    private loader: LoaderService,
    private utilService: UtilServiceService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.initIncidentForm();
    this.filterIncidents();
    this.downloadFile();
  }

  ngAfterViewInit(): void {
    this.incidentsDataSource.paginator = this.paginator;
    this.incidentsDataSource.sort = this.sort;
    this.incidentsDataSource.sortData = this.sortData.bind(this);
  }

  initIncidentForm(): void {
    this.incidentForm = this.fb.group({
      query: [null],
      date_start: [null],
      date_end: [null],
    });
  }

  filterIncidents(): void {
    this.paginator.firstPage();
    this.searchIncidents(1);
  }

  sortData(data: Incident[], sort: Sort): Incident[] {
    this.paginator.firstPage();

    if (!sort.active || sort.direction === '') {
      return data;
    }

    return data.sort((a: Incident, b: Incident) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'declaration_date':
          const decDateA = moment(this.getFormattedDate(a.declaration_date, a.declaration_time), 'DD/MM/YYYY HH:mm');
          const decDateB = moment(this.getFormattedDate(b.declaration_date, b.declaration_time), 'DD/MM/YYYY HH:mm');
          return this.compare(decDateA, decDateB, isAsc);
        case 'creation_date':
          const creaDateA = moment(this.getFormattedDateFromFullDate(a.created_at), 'DD/MM/YYYY HH:mm');
          const creaDateB = moment(this.getFormattedDateFromFullDate(b.created_at), 'DD/MM/YYYY HH:mm');
          return this.compare(creaDateA, creaDateB, isAsc);
        case 'code_implant': return this.compare(a.station.code_implant, b.station.code_implant, isAsc);
        case 'site_name': return this.compare(a.station.info.site_name, b.station.info.site_name, isAsc);
        case 'user':
          const nameA = `${a.user?.last_name.toUpperCase()} ${a.user?.first_name}`;
          const nameB = `${b.user?.last_name.toUpperCase()} ${b.user?.first_name}`;
          return this.compare(nameA, nameB, isAsc);
        case 'role':
          const roleA = a.user?.role?.code;
          const roleB = b.user?.role?.code;
          return this.compare(roleA, roleB, isAsc);
        case 'type':
          return this.compare(a.incident_type.description, b.incident_type.description, isAsc);
        case 'severity':
          const severityA = this.translate.instant('entities.severity_levels.' + a.severity_level.code);
          const severityB = this.translate.instant('entities.severity_levels.' + b.severity_level.code);
          return this.compare(severityA, severityB, isAsc);
        default: return 0;
      }
    });
  }

  searchIncidents(page: number): void {
    const sort = this.getSort();
    const searchInput = this.getSearchInput();

    this.incidentService.getAll({ page, sort, searchInput }).subscribe(
      (data: any[]) => {
        this.incidentsDataSource.data = data['incidents'];

        this.incidentsPaginationItem.total = data['total'];
        this.incidentsPaginationItem.previous_page = this.getPageIndex(data['previous_page']);
        this.incidentsPaginationItem.next_page = this.getPageIndex(data['next_page']);

        this.incidentsTableContainer.nativeElement.scrollTop = 0;
      },
    );
  }

  paginationChanged(event): void {
    const direction = (event.pageIndex > event.previousPageIndex) ? 'next' : 'previous';
    const page = this.incidentsPaginationItem[`${direction}_page`];
    this.searchIncidents(page);
  }

  getFormattedDateFromFullDate(fullDateString: string): string {
    fullDateString = fullDateString.replace('T', ' ').split('.')[0];
    const dateString = fullDateString.split(' ')[0];
    const timeString = fullDateString.split(' ')[1];
    return this.datetimeHelper.getFormattedDateStringFromISOString(dateString, timeString, true);
  }

  getFormattedDate(dateString: string, timeString: string): string {
    return this.datetimeHelper.getFormattedDateStringFromISOString(dateString, timeString);
  }

  clearSearchDate(controlName: string): void {
    this.incidentForm.get(controlName).reset();
  }

  gotoIncidentCard(incidentId: number): void {
    this.router.navigate([absoluteRoutesNames.INCIDENTS, incidentId]);
  }

  exportIncidents(): void {
    this.loader.show();
    this.incidentService.export().subscribe(
      (res: Blob) => {
        this.loader.hide();
        this.utilService.exportXlsx(res, 'incidents');
      },
      (error) => { this.loader.hide(); },
      () => { this.loader.hide(); }
    );
  }

  private getSearchInput(): { query: string; date_start: string; date_end: string; } {
    const dateStart = this.datetimeHelper.getLocaleDateString(this.incidentForm.value.date_start);
    const dateEnd = this.datetimeHelper.getLocaleDateString(this.incidentForm.value.date_end);
    const formattedDateStart = moment(dateStart).toISOString(false);
    const formattedDateEnd = moment(dateEnd).toISOString(false);

    return {
      ...this.incidentForm.value,
      date_start: formattedDateStart,
      date_end: formattedDateEnd
    };
  }

  private getSort(): { active: string, direction: string } {
    if (!this.sort) {
      return { active: 'declaration_date', direction: 'desc' };
    }

    return { active: this.sort.active, direction: this.sort.direction };
  }

  private getPageIndex(page?: string): number {
    if (!page) { return 0; }

    const pageAttributeName = 'page=';

    return +page.substring(page.indexOf(pageAttributeName) + pageAttributeName.length, page.length);
  }

  private compare(a: number | string | moment.Moment, b: number | string | moment.Moment, isAsc: boolean): number {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  downloadFile() {
    if (localStorage.getItem('fileToken')) {
      loaderShow(this.loader);

      
      this.incidentService.getFile(localStorage.getItem('fileToken')).subscribe(
        (response: any) => {
          const filename = response.headers.get('content-disposition').split(';')[1].split('filename')[1].split('=')[1].trim().replace(/\"/g, '');
          saveAs(response.body, filename);
          localStorage.removeItem('fileToken');
          loaderHide(this.loader);
          window.top.close();
        },
        err => {
          console.log(err);
          localStorage.removeItem('fileToken');
          loaderHide(this.loader);
        }
      )
    }
  }

  

}
