<mat-tab-group>
  <mat-tab *ngFor="let repositoryObject of repositoriesObjects">
    <ng-template mat-tab-label>
      <div>{{'repositories.tabs.' +repositoryObject.type + '.title'|translate}}</div>
    </ng-template>
    <ng-template matTabContent>
      <app-basic-objects-tab [basicObjectType]="repositoryObject.type" [withLogo]="repositoryObject.withLogo"
        [withDescription]="repositoryObject.withDescription">
      </app-basic-objects-tab>
    </ng-template>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <div>{{'repositories.tabs.contact_functions.title' | translate}}</div>
    </ng-template>
    <ng-template matTabContent>
      <app-contact-functions-tab>
      </app-contact-functions-tab>
    </ng-template>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <div>{{'repositories.tabs.types.title' | translate}}</div>
    </ng-template>
    <ng-template matTabContent>
      <app-types-tab>
      </app-types-tab>
    </ng-template>
  </mat-tab>
</mat-tab-group>
