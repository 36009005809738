import { MatriceSeverityLevel } from './matrice-severity-level.model';
import { IncidentType } from './incident-type.model';

// tslint:disable:variable-name
export class Matrice {

  id: number;
  incident_type: IncidentType;
  matrice_severity_levels: MatriceSeverityLevel[];

  constructor(data = {}) {
    Object.assign(this, data);
    this.incident_type = new IncidentType(this.incident_type);
  }
}
