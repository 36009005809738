import { ClickToCall } from './../../../../../models/clicktocall.model';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { LoaderService } from '@ff/loader';
import { TranslateService } from '@ngx-translate/core';
import { Incident } from 'src/app/models/incident.model';
import { DatetimeHelperService } from './../../../../shared/services/datetime-helper.service';
import { IncidentService } from './../../../services/incident.service';

@Component({
  selector: 'app-incident-card',
  templateUrl: './incident-card.component.html',
  styleUrls: ['./incident-card.component.scss']
})
export class IncidentCardComponent implements OnInit {

  incident: Incident;

  moderationForm: FormGroup;

  constructor(
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private loader: LoaderService,
    private incidentService: IncidentService,
    private datetimeHelper: DatetimeHelperService,
    private translate: TranslateService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.initData();
  }

  getFormattedDateFromFullDate(fullDateString: string): string {
    fullDateString = fullDateString.replace('T', ' ').split('.')[0];
    const dateString = fullDateString.split(' ')[0];
    const timeString = fullDateString.split(' ')[1];
    return this.datetimeHelper.getFormattedDateStringFromISOString(dateString, timeString, true);
  }

  getFormattedDate(dateString: string, timeString: string): string {
    return this.datetimeHelper.getFormattedDateStringFromISOString(dateString, timeString);
  }

  getCommentText(): SafeHtml {
    const safeComment = this.incident.comment ?
      this.sanitizer.bypassSecurityTrustHtml(this.incident.comment)
      : this.translate.instant('incidents.card.infos.no_comment');
    return safeComment;
  }

  cancelModeration(): void {
    const commentCtrl = this.moderationForm.get('comment');
    commentCtrl.setValue(this.incident.comment);
    commentCtrl.disable();
  }

  enableModeration(): void {
    this.moderationForm.get('comment').enable();
  }

  moderateComment(): void {
    const moderatedComment = this.moderationForm.get('comment').value;
    this.incidentService.moderateComment(this.incident.id, moderatedComment).subscribe(() => {
      this.snackBar.open(this.translate.instant('general.messages.data_saved'));
      this.incident.comment = moderatedComment;
      this.cancelModeration();
    });
  }

  private initForm(): void {
    this.moderationForm = this.fb.group({
      comment: [{ value: null, disabled: true }]
    });
  }

  private initData(): void {
    this.route.params.subscribe(params => {
      const incidentId = +params.id;

      this.loader.show();
      this.incidentService.get(incidentId).subscribe(
        (incident: Incident) => {
          this.incident = incident;
          this.moderationForm.get('comment').setValue(this.incident.comment);
        },
        (error) => { this.loader.hide(); },
        () => { this.loader.hide(); }
      );
    });
  }
}
