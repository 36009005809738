import { authRoutesNames } from '../auth/auth.routes.names';
import { coreRoutesNames } from '../core/core.routes.names';
import { mainRoutesNames } from '../main/main.routes.names';

export const absoluteRoutesNames = {
    LOGIN: '/auth/' + authRoutesNames.LOGIN,
    LOGOUT: '/auth/' + authRoutesNames.LOGOUT,

    ADD: 'add',

    HOME: '/app/' + coreRoutesNames.HOME,
    INCIDENTS: '/app/' + mainRoutesNames.INCIDENTS,
    MATRICES: '/app/' + mainRoutesNames.MATRICES,
    PROVIDERS: '/app/' + mainRoutesNames.PROVIDERS,
    REPOSITORIES: '/app/' + mainRoutesNames.REPOSITORIES,
    STATIONS: '/app/' + mainRoutesNames.STATIONS,
    USERS: '/app/' + mainRoutesNames.USERS,
};
