import { ConfigurationService } from './../../../services/configuration.service';
import { Component, ElementRef, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { LoaderService } from '@ff/loader';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';
import { absoluteRoutesNames } from 'src/app/modules/shared/absolute.routes';
import { validateCodeImplant } from 'src/app/modules/shared/validators/code-implant.validator';
import { Provider } from '../../../../../models/provider.model';
import { BasicObjectService } from '../../../services/basic-object.service';
import { BasicCodedObject } from './../../../../../models/basic-coded-object.model';
import { BasicLabelledObject } from './../../../../../models/basic-labelled-object.model';
import { Contact } from './../../../../../models/contact.model';
import { Station } from './../../../../../models/station.model';
import { ProviderService } from './../../../services/provider.service';
import { StationService } from './../../../services/station.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-station-card',
  templateUrl: './station-card.component.html',
  styleUrls: ['./station-card.component.scss']
})
export class StationCardComponent implements OnInit {

  stationId: number;
  stationForm: FormGroup;

  brands: BasicLabelledObject[];
  contacts: Contact[];
  contactFunctions: BasicLabelledObject[];
  providers: Provider[];
  providerTypes: BasicCodedObject[];
  salesChannels: BasicLabelledObject[];
  segmentations: BasicLabelledObject[];
  slas: BasicLabelledObject[];
  scas: BasicLabelledObject[];
  specifics: BasicLabelledObject[];
  statuses: BasicCodedObject[];
  stationMap: File;
  sensitivitySheet: File;
  station: Station;


  @ViewChild('stationFormElt') stationFormElt: ElementRef;

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private stationService: StationService,
    private providerService: ProviderService,
    private basicObjectService: BasicObjectService,
    private translate: TranslateService,
    private snackBar: MatSnackBar,
    private loader: LoaderService,
    private router: Router,
    private configurationService: ConfigurationService,
    private _cd: ChangeDetectorRef
  ) { }

  get specificsGroup(): FormGroup {
    return this.stationForm.get('specifics') as FormGroup;
  }

  get contactFunctionsArray(): FormArray {
    return this.stationForm.get('contact_functions') as FormArray;
  }

  get providersArray(): FormArray {
    return this.stationForm.get('providers') as FormArray;
  }

  get specificIdsArray(): FormArray {
    return this.stationForm.get('info.specific_ids') as FormArray;
  }

  getSpecificsIds(specifics: BasicLabelledObject[]): number[] {
    const specificIds = specifics ? specifics.map(sp => sp.id) : [];
    return specificIds;
  }

  getContactFunctionIdForRow(rowId: number): number {
    return this.contactFunctionsArray.controls[rowId].get('function_id').value;
  }

  getContactsByFunction(contactFunction: FormGroup): Contact[] {
    if (!this.contacts) { return []; }
    const functionId = contactFunction.get('function_id').value;
    return this.contacts.filter(contact => contact.function_id === functionId);
  }

  ngOnInit(): void {
    this.initLists();

    this.route.params.subscribe(params => {
      this.stationId = (params?.id !== absoluteRoutesNames.ADD) ? +params.id : null;
      if (!this.stationId) {
        this.initForm();
        return;
      }

      this.loader.show();
      this.stationService.get(this.stationId).subscribe(
        (station: Station) => {
          this.station = station;
          this.initForm(station);
        },
        (error) => { this.loader.hide(); },
        () => { this.loader.hide(); }
      );
    });
  }

  initLists(): void {
    const requests = [
      this.stationService.getContacts(),
      this.providerService.getAll(),
      this.basicObjectService.getAll('brands'),
      this.basicObjectService.getAll('contact_functions'),
      this.basicObjectService.getAll('sales_channels'),
      this.basicObjectService.getAll('segmentations'),
      this.basicObjectService.getAll('scas'),
      this.basicObjectService.getAll('specifics'),
      this.basicObjectService.getStatuses(),
    ];
    forkJoin(requests)
      .subscribe(([contacts, providers,
        brands, contactFunctions, salesChannels, segmentations, scas, specifics, statuses]:
        [Contact[], Provider[], BasicLabelledObject[], BasicLabelledObject[],
          BasicLabelledObject[], BasicLabelledObject[], BasicLabelledObject[], BasicLabelledObject[], BasicCodedObject[]]
      ) => {
        this.brands = brands;
        this.contacts = contacts;
        this.contactFunctions = contactFunctions;
        this.providers = providers;
        this.salesChannels = salesChannels;
        this.segmentations = segmentations;
        this.scas = scas;
        this.specifics = specifics;
        this.statuses = statuses;
      });
  }

  initForm(station?: Station): void {
    const stationData = station ? station : new Station();

    this.stationForm = this.fb.group({
      info: this.fb.group({
        id: stationData.id,
        code_implant: [stationData.code_implant, [Validators.required, validateCodeImplant()]],
        status_id: [stationData.status.id, Validators.required],
        brand_id: [stationData.info?.brand_id, Validators.required],
        sales_channel_id: [stationData.info?.sales_channel_id],
        segmentation_id: [stationData.info?.segmentation_id],
        sca_id: [stationData.info?.sca_id],
        site_name: [stationData.info?.site_name],
        client_code: [stationData.info?.client_code],
        client_name: [stationData.info?.client_name],
        establishment_date: [stationData.info?.establishment_date],
        manager_last_name: [stationData.info?.manager_last_name],
        manager_first_name: [stationData.info?.manager_first_name],
        manager_phone: [stationData.info?.manager_phone],
        manager_2_last_name: [stationData.info?.manager_2_last_name],
        manager_2_first_name: [stationData.info?.manager_2_first_name],
        manager_2_phone: [stationData.info?.manager_2_phone],
        email: [stationData.info?.email, Validators.email],
        phone: [stationData.info?.phone],
        status_start_date: [stationData.info?.status_start_date],
        status_end_date: [stationData.info?.status_end_date],
        street: [stationData.info?.street],
        city: [stationData.info?.city],
        zipcode: [stationData.info?.zipcode],
        department: [stationData.info?.department],
        department_name: [stationData.info?.department_name],
        longitude: [stationData.info?.longitude],
        latitude: [stationData.info?.latitude],
        station_map: [stationData.info?.station_map],
        sensitivity_sheet: [stationData.info?.sensitivity_sheet],
        specific_ids: [this.getSpecificsIds(stationData.specifics)],
      }),
      contact_functions: this.fb.array([]),
      providers: this.fb.array([]),
    });

    this.initContactsFormArray(stationData.contacts);
    this.initProvidersFormArray(stationData.providers);

    const codeImplant = this.stationForm.get('info.code_implant');
    if (this.stationId) {
      codeImplant.disable();
      return;
    }
    codeImplant.valueChanges.subscribe(() => {
      codeImplant.patchValue(codeImplant.value.toUpperCase(), { emitEvent: false });
    });
  }

  initContactsFormArray(contacts: Contact[]): void {
    contacts?.forEach(contact => {
      this.addContactFunction(contact);
    });
  }

  initProvidersFormArray(providers: Provider[]): void {
    providers?.forEach(provider => {
      this.addProvider(provider);
    });
  }

  addContactFunction(contact: Contact): void {
    this.contactFunctionsArray.push(this.fb.group({
      function_id: [contact ? contact.function_id : null, Validators.required],
      contact_id: [contact ? contact.id : null, Validators.required]
    }));
  }

  addProvider(provider?: Provider): void {
    this.providersArray.push(
      this.fb.group({
        provider_id: [provider?.id, Validators.required]
      })
    );
  }

  addSpecificFunction(contact: Contact): void {
    this.contactFunctionsArray.push(this.fb.group({
      function_id: [contact ? contact.function_id : null, Validators.required],
      contact_id: [contact ? contact.id : null, Validators.required]
    }));
  }

  removeContact(index: number): void {
    this.contactFunctionsArray.removeAt(index);
  }

  removeProvider(index: number): void {
    this.providersArray.removeAt(index);
  }

  clearDate(dateFormControlName: string): void {
    this.stationForm.get(`info.${dateFormControlName}`).setValue("");
  }

  onBlurCodeImplant(): void {
    if (this.stationId) { return; }

    const codeImplantCtrl = this.stationForm.get('info.code_implant');
    if (!codeImplantCtrl.valid) { return; }

    this.stationService.checkCodeImplantExists(codeImplantCtrl.value).subscribe((res) => {
      if (res.exists) {
        codeImplantCtrl.setErrors({ codeExists: true });
      }
    });
  }

  submitStation(stationForm: FormGroup): void {
    if (!this.stationForm.valid) {
      this.stationForm.markAllAsTouched();

      this.snackBar.open(this.translate.instant('general.messages.errors.required_fields'));
      return;
    }

    const stationData = stationForm.getRawValue();
    const httpRequest = stationData.info.id ?
      this.stationService.update(stationData) : this.stationService.create(stationData);

    httpRequest.subscribe((res) => {
      this.snackBar.open(this.translate.instant('general.messages.data_saved'));
      if (!this.stationId) {
        if(this.stationMap) this.addFilesToStations('station_map', this.stationMap, res)
        if(this.sensitivitySheet) this.addFilesToStations('sensitivity_sheet', this.sensitivitySheet, res)
      }
      this.gotoStations();
    });
  }

  getProviderLabel(provider: Provider): string {
    const providerTypeLabel = this.translate.instant('entities.provider_types.' + provider.provider_type.code);
    const providerLabel = `${provider.company} (${providerTypeLabel})`;
    return providerLabel;
  }

  gotoStations(): void {
    this.router.navigate([absoluteRoutesNames.STATIONS]);
  }

  addFilesToStations(filename, file, stationId):void {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.stationService.addFiles(stationId, { name: filename, type: 'application/pdf', data: reader.result }).subscribe(
        data => this.snackBar.open(data.message),
        err => this.snackBar.open("Erreur interne, essayer plus tard!")
      );
    };
  }

  docChange(event, fileName: string): void {
    const reader = new FileReader();
    const fileList: FileList = event.target.files;
    if (fileList && fileList.length > 0) {
      const file: File = event.target.files[0];
      if (this.stationId) {
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.stationService.addFiles(this.stationId, { name: fileName, type: 'application/pdf', data: reader.result }).subscribe(
            data => this.snackBar.open(data.message),
            err => this.snackBar.open("Erreur interne, essayer plus tard!")
          );
        };
      }else{
        switch (fileName) {
          case 'station_map':
            this.stationMap = file;
            break;
          case 'sensitivity_sheet':
            this.sensitivitySheet = file;
            break;
          default:
            break;
        }
      }
      this._cd.markForCheck();
    }
  }

  openLink(e: any, link: string): void {
    e.preventDefault();
    link = link.replace('documents/', '');
    link = environment.api.host + '/getFile/' + link;
    window.open(link, '_blank');
  }
}
