import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { BasicCodedObject } from 'src/app/models/basic-coded-object.model';
import { Provider } from 'src/app/models/provider.model';
import { BasicObjectService } from './../../../services/basic-object.service';

@Component({
  selector: 'app-provider-create-update-dialog',
  templateUrl: './provider-create-update-dialog.component.html',
  styleUrls: ['./provider-create-update-dialog.component.scss']
})
export class ProviderCreateUpdateDialogComponent implements OnInit {

  isNewProvider: boolean;
  providerTypes: BasicCodedObject[];

  providerForm: FormGroup;

  constructor(
    private basicObjectService: BasicObjectService,
    private fb: FormBuilder,
    private translate: TranslateService,
    public dialogRef: MatDialogRef<ProviderCreateUpdateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public providerData?: Provider
  ) { }

  ngOnInit(): void {
    this.isNewProvider = this.providerData === undefined;

    this.getProviderTypes();
    this.initForm();
  }

  submitProvider(providerForm: FormGroup): void {
    if (!providerForm.valid) {
      providerForm.markAllAsTouched();
      return;
    }

    this.dialogRef.close({
      data: providerForm.value
    });
  }

  private getProviderTypes(): void {
    this.basicObjectService.getTypes().subscribe(
      (providerTypes: BasicCodedObject[]) => {
        this.providerTypes = providerTypes.sort((a, b) => {
          const aString: string = this.translate.instant(`entities.provider_types.${a.code}`);
          const bString: string = this.translate.instant(`entities.provider_types.${b.code}`);
          return (aString.toUpperCase().localeCompare(bString.toUpperCase()));
        });
      }
    );
  }

  private initForm(): void {
    const formData = new Provider(this.providerData);

    this.providerForm = this.fb.group({
      id: formData.id,
      company: [formData.company, Validators.required],
      role: [formData.role, Validators.required],
      role_visitor_dcm: [formData.role_visitor_dcm],
      provider_type_id: [formData.provider_type_id, Validators.required],
      // logo: [formData.logo, Validators.required],
      phone_1: [formData.phone_1, Validators.required],
      phone_2: [formData.phone_2],
      phone_3: [formData.phone_3],
      email: [formData.email],
      email_2: [formData.email_2],
      opening_hours: [formData.opening_hours],
      geographic_scope: [formData.geographic_scope]
    });
  }
}
