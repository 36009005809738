<form [formGroup]="basicObjectsForm" novalidate>
  <mat-card>
    <mat-card-content>
      <div class="basic-objects-list" formArrayName="basicObjects">
        <div class="basic-object" *ngFor="let basicObject of basicObjectsArray.controls">
          <ng-container [formGroup]="basicObject">
            <mat-form-field>
              <mat-label>{{'repositories.tabs.label' | translate}}</mat-label>
              <input matInput formControlName="label" class="basic-object-input">
              <div class="d-flex ml-2" matSuffix>
                <mat-icon class="pointer mr-2" color="primary" (click)="switchBasicObjectEdit(basicObject)"
                  *ngIf="basicObject.disabled">edit</mat-icon>
                <mat-icon class="pointer" color="primary" (click)="deleteBasicObject(basicObject)"
                  *ngIf="basicObject.disabled">
                  delete
                </mat-icon>
                <mat-icon class="pointer mr-2" color="primary" (click)="saveBasicObject(basicObject)"
                  *ngIf="basicObject.enabled">
                  check</mat-icon>
                <mat-icon class="pointer" color="primary" (click)="switchBasicObjectEdit(basicObject)"
                  *ngIf="basicObject.enabled">
                  clear</mat-icon>
              </div>
            </mat-form-field>

            <mat-form-field *ngIf="withDescription" class="description-container">
              <mat-label>{{'repositories.tabs.description' | translate}}</mat-label>
              <input matInput formControlName="description" class="basic-object-input">
            </mat-form-field>
          </ng-container>

          <div *ngIf="withLogo" class="logo-container">
            <ng-container
              *ngIf="basicObject.controls.initialLogo?.value && !basicObject.controls.logo.value; else noLogoTemplate">
              <img class="logo" [src]="basicObject.controls.initialLogo.value" (click)="logoUploader.click()" />
            </ng-container>
            <ng-template #noLogoTemplate>
              <mat-icon class="logo no-logo" color="primary" (click)="logoUploader.click()"
                *ngIf="!basicObject.controls.logo.value">add_photo_alternate</mat-icon>
              <img class="logo" [src]="basicObject.controls.logo.value" (click)="logoUploader.click()"
                *ngIf="basicObject.controls.logo.value" />
            </ng-template>
            <div *ngIf="basicObject.controls.logo?.hasError('tooLarge')">
              <mat-error color="primary">{{'repositories.tabs.logo_too_large' | translate}}</mat-error>
            </div>
            <div *ngIf="basicObject.controls.logo?.value">
              <mat-icon class="pointer mr-2" color="primary" (click)="saveBasicObject(basicObject)">check</mat-icon>
              <mat-icon class="pointer" color="primary" (click)="clearChoosenLogo(basicObject)">clear</mat-icon>
            </div>
            <input #logoUploader type="file" hidden accept="image/png, image/jpeg"
              (change)="handleLogoInput($event.target.files, basicObject)" />
          </div>
        </div>
      </div>
    </mat-card-content>
    <mat-card-actions>
      <button mat-flat-button color="primary" (click)="addBasicObject()">
        <mat-icon>add</mat-icon>{{'repositories.tabs.'+ basicObjectType +'.add' | translate}}
      </button>
    </mat-card-actions>
  </mat-card>
</form>
