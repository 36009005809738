<header>
  <div class="header-left" *ngIf="!authService.isLoggedIn().getValue()">
    <a [routerLink]="'/app/home'" class="home-link">
      <img src="assets/logo.svg" title="TotalEnergies" alt="TotalEnergies" class="logo" height="76" />
    </a>
  </div>
  <div class="header-right">
    <div class="header-right-top">
      <div class="app-name px-3" [ngClass]="{'text-center': authService.isLoggedIn().getValue()}">
        <h3 class="text pointer primary" [routerLink]="'/app/incidents'">
          {{'general.app_name' | translate}}
        </h3>
      </div>
      <div class="header-info-container mr-3">
        <ng-container *ngIf="userService.getUser() | async">
          <span class="pointer d-flex align-items-center user" [matMenuTriggerFor]="account">
            <span class="username">{{userService.getUser().getValue().first_name}}
              {{userService.getUser().getValue().last_name}}</span>
            <mat-icon class="ml-3 user-icon">account_circle</mat-icon>
            <mat-icon color="primary" class="arrow-icon">arrow_drop_down</mat-icon>
          </span>
          <mat-menu class="mt-2" #account="matMenu" [overlapTrigger]="false">
            <button mat-menu-item class="pr-5" routerLink="/user/my-account">My account</button>
            <button mat-menu-item routerLink="/auth/logout">Logout</button>
          </mat-menu>
        </ng-container>
      </div>
    </div>
  </div>
</header>
