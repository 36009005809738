import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BasicCodedObject } from 'src/app/models/basic-coded-object.model';
import { BasicLabelledObject } from 'src/app/models/basic-labelled-object.model';
import { environment } from 'src/environments/environment';
import { ErrorHandlerService } from '../../shared/services/error-handler.service';
import { endpoints } from './../../../../environments/endpoints';

@Injectable({
  providedIn: 'root'
})
export class BasicObjectService {
  constructor(
    private http: HttpClient,
    private errorHandlerService: ErrorHandlerService
  ) { }

  getAll(basicObjectType: string): Observable<BasicLabelledObject[]> {
    return this.getBasicLabelledObjectList(endpoints.labelled_objects[basicObjectType]);
  }

  create(basicObjectType: string, basicObjectValue: any): Observable<any> {
    return this.postBasicObject(endpoints.labelled_objects[basicObjectType], basicObjectValue);
  }

  update(basicObjectType: string, id: number, basicObjectValue: any): Observable<any> {
    return this.patchBasicObject(endpoints.labelled_objects[basicObjectType], basicObjectValue, id);
  }

  delete(basicObjectType: string, id: number): Observable<any> {
    return this.deleteBasicObject(endpoints.labelled_objects[basicObjectType], id);
  }

  getStatuses(): Observable<BasicCodedObject[]> {
    return this.getBasicCodedObjectList(endpoints.coded_objects.station_statuses);
  }

  getTypes(): Observable<BasicCodedObject[]> {
    return this.getBasicCodedObjectList(endpoints.coded_objects.providerTypes);
  }

  createType(type: BasicCodedObject): Observable<any> {
    return this.postBasicObject(endpoints.coded_objects.providerTypes, type);
  }

  deleteType(id: number): Observable<any> {
    return this.deleteBasicObject(endpoints.coded_objects.providerTypes, id);
  }

  updateType(id: number, type: BasicCodedObject): Observable<any> {
    return this.patchBasicObject(endpoints.coded_objects.providerTypes, type, id);
  }

  private getBasicCodedObjectList(endpoint: string): Observable<BasicCodedObject[]> {
    const resName = endpoint.replace('-', '_');

    return this.http.get(
      `${environment.api.host}/${endpoint}`
    ).pipe(
      map(
        (res: any) => res.data[resName].map(item => new BasicCodedObject(item))
      ),
      catchError(err => this.errorHandlerService.handleError(err))
    );
  }

  private getBasicLabelledObjectList(endpoint: string): Observable<BasicLabelledObject[]> {
    const resName = endpoint.replace('-', '_');

    return this.http.get(
      `${environment.api.host}/${endpoint}`
    ).pipe(
      map(
        (res: any) => res.data[resName].map(item => new BasicLabelledObject(item))
      ),
      catchError(err => this.errorHandlerService.handleError(err))
    );
  }

  private postBasicObject(endpoint: string, basicObjectValue: any): Observable<any> {
    return this.http.post(
      `${environment.api.host}/${endpoint}`, basicObjectValue
    ).pipe(
      map(
        (res: any) => res.data
      ),
      catchError(err => this.errorHandlerService.handleError(err))
    );
  }

  private patchBasicObject(endpoint: string, basicObjectValue: any, id: number): Observable<any> {
    return this.http.patch(
      `${environment.api.host}/${endpoint}/${id}`, basicObjectValue
    ).pipe(
      map(
        (res: any) => res.data
      ),
      catchError(err => this.errorHandlerService.handleError(err))
    );
  }

  private deleteBasicObject(endpoint: string, id: number): Observable<any> {
    return this.http.delete(
      `${environment.api.host}/${endpoint}/${id}`
    ).pipe(
      map(
        (res: any) => res.data
      ),
      catchError(err => this.errorHandlerService.handleError(err))
    );
  }
}
