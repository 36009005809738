import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { endpoints } from './../../../../environments/endpoints';
import { environment } from './../../../../environments/environment';
import { Contact } from './../../../models/contact.model';
import { Station } from './../../../models/station.model';
import { StationsList as StationsListItem } from './../../../models/stations-list.model';
import { ErrorHandlerService } from './../../shared/services/error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class StationService {
  constructor(
    private http: HttpClient,
    private errorHandlerService: ErrorHandlerService
  ) { }

  getAll(params: { page: number, sort: { active: string, direction: string }, filter: string }): Observable<any[]> {
    let url = `${environment.api.host}${endpoints.stations}`
      + `?page=${params.page}&sort_active=${params.sort.active}&sort_direction=${params.sort.direction}`;
    if (params.filter) {
      url += `&filter=${params.filter}`;
    }

    return this.http.get(url).pipe(
      map(
        (res: any) => {
          res.data['stations'] = res.data.stations.map(item => new StationsListItem(item));
          return res.data;
        }
      ),
      catchError(err => this.errorHandlerService.handleError(err))
    );
  }

  get(stationId: number): Observable<Station> {
    return this.http.get(
      `${environment.api.host}${endpoints.stations}/${stationId}`
    ).pipe(
      map(
        (res: any) => new Station(res.data.station)
      ),
      catchError(err => this.errorHandlerService.handleError(err))
    );
  }

  getByCodeImplant(code_implant: string) {
    return this.http.get(
      `${environment.api.host}${endpoints.stations}/code_implant/${code_implant}`
    ).pipe(
      map(
        (res: any) => new Station(res.data.station)
      ),
      catchError(err => this.errorHandlerService.handleError(err))
    );
  }

  create(stationData: any): Observable<any> {
    return this.http.post(
      `${environment.api.host}${endpoints.stations}`,
      stationData
    ).pipe(
      map(
        (res: any) => res.data
      ),
      catchError(err => this.errorHandlerService.handleError(err))
    );
  }

  update(stationData: any): Observable<any> {
    return this.http.put(
      `${environment.api.host}${endpoints.stations}/${stationData.info.id}`,
      stationData
    ).pipe(
      map(
        (res: any) => res.data
      ),
      catchError(err => this.errorHandlerService.handleError(err))
    );
  }

  updateSelectedContactFunctions(data: { selected_contact_functions_ids: number[] }): Observable<any> {
    return this.http.post(
      `${environment.api.host}/${endpoints.labelled_objects.contact_functions}/mass-update`,
      { selected_contact_functions_ids: data.selected_contact_functions_ids }
    ).pipe(
      map(
        (res: any) => res.data
      ),
      catchError(err => this.errorHandlerService.handleError(err))
    );
  }

  updateSelectedProviderTypes(data: { selected_provider_types_ids: number[] }): Observable<any> {
    return this.http.post(
      `${environment.api.host}/${endpoints.coded_objects.providerTypes}/mass-update`,
      { selected_provider_types_ids: data.selected_provider_types_ids }
    ).pipe(
      map(
        (res: any) => res.data
      ),
      catchError(err => this.errorHandlerService.handleError(err))
    );
  }

  getContacts(): Observable<Contact[]> {
    return this.http.get(
      `${environment.api.host}${endpoints.contacts}`
    ).pipe(
      map(
        (res: any) => res.data.contacts.map(contact => new Contact(contact))
      ),
      catchError(err => this.errorHandlerService.handleError(err))
    );
  }

  getContactsByFunction(functionId: number): Observable<Contact[]> {
    return this.http.get(
      `${environment.api.host}${endpoints.labelled_objects.contact_functions}/${functionId}/${endpoints.contacts}`
    ).pipe(
      map(
        (res: any) => res.data.contacts.map(contact => new Contact(contact))
      ),
      catchError(err => this.errorHandlerService.handleError(err))
    );
  }

  checkCodeImplantExists(code: string): Observable<any> {
    return this.http.get(
      `${environment.api.host}${endpoints.stations}${endpoints.check_code}/${code}`
    ).pipe(
      map(
        (res: any) => res.data
      ),
      catchError(err => this.errorHandlerService.handleError(err))
    );
  }

  export2(): Observable<Blob> {
    return this.http.post(environment.api.host + endpoints.stations + endpoints.export,
      null,
      { responseType: 'blob' }
    ).pipe(
      map(res => res),
      catchError(err => this.errorHandlerService.handleError(err))
    );
  }

  export() {
    const url = environment.api.host + endpoints.stations + endpoints.export;
    return this.http.post(url, null, {responseType: 'blob', observe: 'response'});
  }

  addFiles(id: number, fileData: { name: string; type: string; data: any; }): Observable<any> {
    return this.http.post(environment.api.host + endpoints.stations + '/' + id + '/addFiles', { file: fileData });
  }

  synchStations(): Observable<any> {
    return this.http.get(
      `${environment.api.host}${endpoints.stations}/sync-status`
    ).pipe(
      map(
        (res: any) => res
      ),
      catchError(err => this.errorHandlerService.handleError(err))
    );
  }
}
