<div class="main-content">
  <h2>{{'incidents.card.title' | translate}}</h2>

  <div class="mb-5" *ngIf="incident">
    <mat-card>
      <mat-card-content>
        <div class="card-infos">
          <div class="line">
            <div class="label">{{'incidents.card.infos.code_implant' | translate}}</div>
            <div class="value">{{incident.station.code_implant}}</div>
          </div>
          <div class="line">
            <div class="label">{{'incidents.card.infos.site_name' | translate}}</div>
            <div class="value">{{incident.station.info.site_name}}</div>
          </div>
          <div class="line">
            <div class="label">Ville</div>
            <div class="value">{{incident.station.info.city}}</div>
          </div>
          <div class="line">
            <div class="label">CP</div>
            <div class="value">{{incident.station.info.zipcode}}</div>
          </div>
          <div class="line">
            <div class="label">{{'incidents.card.infos.provider' | translate}}</div>
            <div class="value">{{incident.station.info.client_name}}</div>
          </div>
          <div class="line">
            <div class="label">{{'incidents.card.infos.date' | translate}}</div>
            <div class="value">{{getFormattedDate(incident.declaration_date, incident.declaration_time)}}</div>
          </div>
          <div class="line">
            <div class="label">{{'incidents.card.infos.creation_date' | translate}}</div>
            <div class="value">{{getFormattedDateFromFullDate(incident.created_at)}}</div>
          </div>
          <div class="line">
            <div class="label">{{'incidents.card.infos.contact_type' | translate}}</div>
            <div class="value">{{incident.contact_type}}</div>
          </div>
          <div class="line">
            <div class="label">{{'incidents.card.infos.contact' | translate}}</div>
            <div class="value">{{incident.contact}}</div>
          </div>
          <div class="line">
            <div class="label">{{'incidents.card.infos.names' | translate}}</div>
            <div class="value">{{incident.contact_name}}</div>
          </div>
          <div class="line">
            <div class="label">{{'incidents.card.infos.incident_types' | translate}}</div>
            <div class="value">{{incident.incident_type?.description}}</div>
          </div>
          <div class="line">
            <div class="label">{{'incidents.card.infos.severity_levels' | translate}}</div>
            <div class="value">{{incident.severity_lvl_name}}</div>
          </div>
          <div class="line">
            <div class="label">{{'incidents.card.infos.severity' | translate}}</div>
            <div class="value">{{'entities.severity_levels.'+ incident.severity_level.code | translate}}</div>
            <div class="value mt-1">{{incident.incident_type?.description}}</div>
            <div class="value mt-1">{{incident.itsl_description}}</div>
          </div>
          <div class="line">
            <div class="label">{{'incidents.card.infos.comment' | translate}}</div>
            <div class="value">{{incident?.comment}}</div>
          </div>
          <div class="line">
            <div class="label">{{'incidents.card.infos.user' | translate}}</div>
            <div class="value">{{(incident.user.last_name|uppercase) + " " + incident.user.first_name}}</div>
          </div>
          <div class="line">
            <div class="label">{{'incidents.card.infos.astreinte_type' | translate}}</div>
            <div class="value">{{incident.incident_type?.type}}</div>
          </div>
          <div class="line">
            <div class="label">{{'incidents.card.infos.status' | translate}}</div>
            <div class="value">{{'entities.incident_types.statuses.' + incident.status.code | translate}}</div>
          </div>          
        </div>
        <hr class="my-4">
        <ng-template #noCommentTemplate>
          <div class="line">
            <div class="label">{{'incidents.card.infos.no_comment' | translate}}</div>
          </div>
        </ng-template>
      </mat-card-content>
    </mat-card>
  </div>
</div>
