import { BasicCodedObject } from './basic-coded-object.model';

// tslint:disable:variable-name
export class User {

  id: number;
  igg?: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  role: BasicCodedObject;

  constructor(data = {}) {
    Object.assign(this, data);
    this.role = new BasicCodedObject(this.role);
  }

  isAdmin(): boolean {
    return this.role.code.toLocaleUpperCase() === 'ADMIN';
  }
}
