import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { authRoutes } from './auth.routes';
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';
import { ResetPasswordComponent } from './components/reset-password/resetPassword.component';
import { ResetPasswordLinkComponent } from './components/reset-password-link/resetPasswordLink.component';
import { SharedModule } from '../shared/shared.module';
import { OAuth2Module } from '@ff/oauth2';

@NgModule({
  declarations: [
    LoginComponent,
    LogoutComponent,
    ResetPasswordComponent,
    ResetPasswordLinkComponent
  ],
  entryComponents: [
    ResetPasswordComponent,
    ResetPasswordLinkComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    OAuth2Module,
    RouterModule.forChild(authRoutes)
  ],
})
export class AuthModule {

  public constructor() {

  }

}
