<div class="main-content">
  <h2 class="text-center">
    {{(stationId? 'stations.station_card.title_update' : 'stations.station_card.title_create') | translate}}
  </h2>

  <form #stationFormElt class="create-station-form mt-3" [formGroup]="stationForm"
    (ngSubmit)="submitStation(stationForm)" novalidate *ngIf="stationForm">
    <ng-container formGroupName="info">
      <h3>{{'stations.station_card.info' | translate}}</h3>
      <mat-card>
        <mat-card-content class="card-infos">
          <mat-form-field>
            <mat-label>{{'entities.stations.code_implant' | translate}}</mat-label>
            <input matInput formControlName="code_implant" maxlength="8" (blur)="onBlurCodeImplant()">
            <mat-error *ngIf="stationForm.get('info.code_implant').hasError('codeExists')">
              {{'api.errors.stations.code_exists' | translate}}
            </mat-error>
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{'entities.stations.info.site_name' | translate}}</mat-label>
            <input matInput formControlName="site_name">
          </mat-form-field>
          <div></div>

          <mat-form-field>
            <mat-label>{{'entities.stations.info.client_code' | translate}}</mat-label>
            <input matInput formControlName="client_code">
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{'entities.stations.info.client_name' | translate}}</mat-label>
            <input matInput formControlName="client_name">
          </mat-form-field>
          <div class="d-flex align-items-baseline">
            <mat-form-field class="disabled-input-datepicker" (click)="establishmentDate.open()">
              <mat-label>{{'entities.stations.info.establishment_date' | translate}}</mat-label>
              <input matInput class="pointer" [matDatepicker]="establishmentDate" formControlName="establishment_date"
                readonly>
              <mat-datepicker-toggle matSuffix [for]="establishmentDate"></mat-datepicker-toggle>
              <mat-datepicker #establishmentDate></mat-datepicker>
            </mat-form-field>
            <mat-icon class="ml-2 pointer" (click)="clearDate('establishment_date')">clear</mat-icon>
          </div>
          <mat-form-field>
            <mat-label>{{'entities.stations.info.brand' | translate}}</mat-label>
            <mat-select formControlName="brand_id">
              <mat-option *ngFor="let brand of brands" [value]="brand.id">
                {{brand.label}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{'entities.stations.info.sales_channel' | translate}}</mat-label>
            <mat-select formControlName="sales_channel_id">
              <mat-option *ngFor="let salesChannel of salesChannels" [value]="salesChannel.id">
                {{salesChannel.label}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div></div>

          <mat-form-field>
            <mat-label>{{'entities.stations.info.specifics' | translate}}</mat-label>
            <mat-select formControlName="specific_ids" multiple>
              <mat-option *ngFor="let specific of specifics" [value]="specific.id">
                {{specific.label}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{'entities.stations.info.segmentation' | translate}}</mat-label>
            <mat-select formControlName="segmentation_id">
              <mat-option *ngFor="let segmentation of segmentations" [value]="segmentation.id">
                {{segmentation.label}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{'entities.stations.info.sca' | translate}}</mat-label>
            <mat-select formControlName="sca_id">
              <mat-option *ngFor="let sca of scas" [value]="sca.id">
                {{sca.label}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{'entities.stations.status' | translate}}</mat-label>
            <mat-select formControlName="status_id">
              <mat-option *ngFor="let status of statuses" [value]="status.id">
                <div [class]="'mr-2 station-status-bullet ' + status.code"></div>
                {{'entities.station_statuses.' + status.code | translate}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div class="d-flex align-items-baseline">
            <mat-form-field class="disabled-input-datepicker" (click)="statusStartDate.open()">
              <mat-label>{{'entities.stations.info.status_start_date' | translate}}</mat-label>
              <input matInput class="pointer" [matDatepicker]="statusStartDate" formControlName="status_start_date"
                readonly>
              <mat-datepicker-toggle matSuffix [for]="statusStartDate"></mat-datepicker-toggle>
              <mat-datepicker #statusStartDate></mat-datepicker>
            </mat-form-field>
            <mat-icon class="ml-2 pointer" (click)="clearDate('status_start_date')">clear</mat-icon>
          </div>
          <div class="d-flex align-items-baseline">
            <mat-form-field class="disabled-input-datepicker" (click)="statusEndDate.open()">
              <mat-label>{{'entities.stations.info.status_end_date' | translate}}</mat-label>
              <input matInput class="pointer" [matDatepicker]="statusEndDate" formControlName="status_end_date"
                readonly>
              <mat-datepicker-toggle matSuffix [for]="statusEndDate"></mat-datepicker-toggle>
              <mat-datepicker #statusEndDate></mat-datepicker>
            </mat-form-field>
            <mat-icon class="ml-2 pointer" (click)="clearDate('status_end_date')">clear</mat-icon>
          </div>

        </mat-card-content>
      </mat-card>

      <h3 class="mt-5">{{'stations.station_card.geographic_data' | translate}}</h3>
      <mat-card>
        <mat-card-content class="card-infos">
          <mat-form-field>
            <mat-label>{{'entities.stations.info.geographic_data.street' | translate}}</mat-label>
            <input matInput formControlName="street">
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{'entities.stations.info.geographic_data.city' | translate}}</mat-label>
            <input matInput formControlName="city">
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{'entities.stations.info.geographic_data.zipcode' | translate}}</mat-label>
            <input matInput formControlName="zipcode">
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{'entities.stations.info.geographic_data.department' | translate}}</mat-label>
            <input matInput formControlName="department">
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{'entities.stations.info.geographic_data.department_name' | translate}}</mat-label>
            <input matInput formControlName="department_name">
          </mat-form-field>
          <div></div>
          <mat-form-field>
            <mat-label>{{'entities.stations.info.geographic_data.longitude' | translate}}</mat-label>
            <input matInput formControlName="longitude">
            <mat-hint>{{'entities.stations.info.geographic_data.longitude_hint' | translate}}</mat-hint>
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{'entities.stations.info.geographic_data.latitude' | translate}}</mat-label>
            <input matInput formControlName="latitude">
            <mat-hint>{{'entities.stations.info.geographic_data.latitude_hint' | translate}}</mat-hint>
          </mat-form-field>
          <div></div>
        </mat-card-content>
        <div class="mb-4">
          <div class="d-flex justify-content-between">
            <div class="d-flex align-items-center mb-2">
              <mat-icon mat-list-icon class="mr-2">description</mat-icon>
              <div>{{'entities.stations.info.geographic_data.station_map' | translate}}</div>
            </div>
            <div class="align-btns">
              <div *ngIf="station?.info?.station_map">
                <button mat-icon-button color="primary" (click)="openLink($event, station?.info?.station_map)" aria-label="View {{'entities.stations.info.geographic_data.station_map' | translate}}">
                  <mat-icon class="mr-2">visibility</mat-icon>
                </button>
              </div>
              <div>
                <input type="file" #stationMapInput hidden (change)="docChange($event, 'station_map')" accept="application/pdf" />
                <button mat-flat-button color="secondary" type="button" (click)="stationMapInput.click()">
                  <mat-icon class="mr-2">save</mat-icon>
                  {{'configuration.documents.upload' | translate}}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="mb-4">
          <div class="d-flex justify-content-between">
            <div class="d-flex align-items-center mb-2">
              <mat-icon mat-list-icon class="mr-2">description</mat-icon>
              <div>{{'entities.stations.info.geographic_data.sensitivity_sheet' | translate}}</div>
            </div>
            <div class="align-btns">
              <div *ngIf="station?.info?.sensitivity_sheet">
                <button mat-icon-button color="primary" (click)="openLink($event, station?.info?.sensitivity_sheet)" aria-label="View {{'entities.stations.info.geographic_data.sensitivity_sheet' | translate}}">
                  <mat-icon class="mr-2">visibility</mat-icon>
                </button>
              </div>
              <div>
                <input type="file" #sensitivitySheetInput hidden (change)="docChange($event, 'sensitivity_sheet')" accept="application/pdf" />
                <button mat-flat-button color="secondary" type="button" (click)="sensitivitySheetInput.click()">
                  <mat-icon class="mr-2">save</mat-icon>
                  {{'configuration.documents.upload' | translate}}
                </button>
              </div>
            </div>
          </div>
        </div>
      </mat-card>

      <h3 class="mt-5">{{'stations.station_card.client_contact.title' | translate}}</h3>
      <mat-card>
        <mat-card-content class="card-infos">
          <mat-form-field>
            <mat-label>{{'entities.stations.info.manager_last_name' | translate}}</mat-label>
            <input matInput formControlName="manager_last_name">
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{'entities.stations.info.manager_first_name' | translate}}</mat-label>
            <input matInput formControlName="manager_first_name">
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{'entities.stations.info.manager_phone' | translate}}</mat-label>
            <input matInput formControlName="manager_phone">
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{'entities.stations.info.manager_2_last_name' | translate}}</mat-label>
            <input matInput formControlName="manager_2_last_name">
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{'entities.stations.info.manager_2_first_name' | translate}}</mat-label>
            <input matInput formControlName="manager_2_first_name">
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{'entities.stations.info.manager_2_phone' | translate}}</mat-label>
            <input matInput formControlName="manager_2_phone">
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{'entities.stations.info.email' | translate}}</mat-label>
            <input matInput formControlName="email">
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{'entities.stations.info.phone' | translate}}</mat-label>
            <input matInput formControlName="phone">
          </mat-form-field>
          <div></div>
        </mat-card-content>
      </mat-card>
    </ng-container>

    <h3 class="mt-5">{{'stations.station_card.contacts.title' | translate}}</h3>
    <mat-card>
      <mat-card-content class="mb-0">
        <div formArrayName="contact_functions">
          <ng-container *ngFor="let contactFunctionGroup of contactFunctionsArray.controls; let i=index">
            <div [formGroup]="contactFunctionGroup" class="card-infos">
              <mat-form-field>
                <mat-label>{{'stations.station_card.contacts.contact_function' | translate}}</mat-label>
                <mat-select formControlName="function_id">
                  <mat-option *ngFor="let contactFunction of contactFunctions" [value]="contactFunction.id">
                    {{contactFunction.label}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <div>
                <mat-form-field *ngIf="contactFunctionGroup.get('function_id').value">
                  <mat-label>{{'stations.station_card.contacts.contact' | translate}}</mat-label>
                  <mat-select formControlName="contact_id">
                    <mat-option *ngFor="let contact of getContactsByFunction(contactFunctionGroup)"
                      [value]="contact.id">
                      {{contact.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div>
                <div class="pt-2 d-flex justify-content-center">
                  <button mat-icon-button color="primary" (click)="removeContact(i)">
                    <mat-icon>delete</mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </mat-card-content>
      <mat-card-actions>
        <button mat-flat-button type="button" color="primary" (click)="addContactFunction()">
          <mat-icon class="mr-1">add</mat-icon>{{'stations.station_card.contacts.add' | translate}}
        </button>
      </mat-card-actions>
    </mat-card>

    <h3 class="mt-5">{{'stations.station_card.providers.title' | translate}}</h3>
    <mat-card>
      <mat-card-content class="mb-0">
        <div formArrayName="providers">
          <ng-container *ngFor="let provider of providersArray.controls; let i=index">
            <div [formGroup]="provider" class="card-infos">
              <mat-form-field>
                <mat-label>{{'stations.station_card.providers.provider' | translate}}</mat-label>
                <mat-select formControlName="provider_id">
                  <mat-option *ngFor="let provider of providers" [value]="provider.id">
                    {{getProviderLabel(provider)}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <div>
                <div class="pt-2 d-flex justify-content-center">
                  <button mat-icon-button color="primary" (click)="removeProvider(i)">
                    <mat-icon>delete</mat-icon>
                  </button>
                </div>
              </div>
              <div></div>
            </div>
          </ng-container>
        </div>
      </mat-card-content>
      <mat-card-actions>
        <button mat-flat-button type="button" color="primary" (click)="addProvider()">
          <mat-icon class="mr-1">add</mat-icon>{{'stations.station_card.providers.add' | translate}}
        </button>
      </mat-card-actions>
    </mat-card>
  </form>

  <div class="page-actions mt-3">
    <button mat-flat-button class="mr-3" (click)="gotoStations()">{{'general.buttons.cancel' | translate}}</button>
    <button mat-flat-button color="primary" (click)="submitStation(stationForm)">
      {{'general.buttons.validate' | translate}}
    </button>
  </div>
</div>
