import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-repositories',
  templateUrl: './repositories.component.html',
  styleUrls: ['./repositories.component.scss']
})
export class RepositoriesComponent implements OnInit {

  repositoriesObjects = [
    { type: 'specifics', withLogo: false, withDescription: true },
    { type: 'brands', withLogo: true, withDescription: false },
    { type: 'sales_channels', withLogo: false, withDescription: false },
    { type: 'segmentations', withLogo: false, withDescription: false },
    { type: 'slas', withLogo: false, withDescription: false },
    { type: 'scas', withLogo: false, withDescription: false },
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
