
// tslint:disable:variable-name
export class BasicLabelledObject {

  id: number;
  order?: number;
  label: string;
  description?: string;
  is_displayed?: boolean;
  logo?: string;

  constructor(data = {}) {
    Object.assign(this, data);
  }
}
