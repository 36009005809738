import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { isFunction } from 'util';

@Injectable({
  providedIn: 'root'
})
export class DatetimeHelperService {

  constructor() { }

  getLocaleDateString(date: Date): string {
    return moment(date).toLocaleString();
  }

  getFormattedDateStringFromISOString(dateString: string, timeString?: string, withTimezone = false): string {
    if (!timeString) {
      timeString = '00:00';
    }

    const date = moment(dateString + " " + timeString);
    if(withTimezone) {
      const offset = new Date().getTimezoneOffset();
      date.subtract(offset, 'm');
    }

    return date.format('DD/MM/YYYY HH:mm');
  }
}
