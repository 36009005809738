import { AngularEditorConfig } from '@kolkov/angular-editor';

export const editorConfig: AngularEditorConfig = {
  editable: true,
  spellcheck: true,
  height: 'auto',
  minHeight: '0',
  maxHeight: '33vh',
  width: '100%',
  minWidth: '0',
  translate: 'yes',
  enableToolbar: true,
  showToolbar: true,
  placeholder: 'Ajouter le texte de la fiche réflexe ici...',
  defaultParagraphSeparator: '',
  defaultFontName: 'Arial',
  defaultFontSize: '3',
  fonts: [
    { class: 'arial', name: 'Arial' },
    { class: 'calibri', name: 'Calibri' },
    { class: 'times-new-roman', name: 'Times New Roman' }
  ],
  toolbarHiddenButtons: [
    [
      // 'undo',
      // 'redo',
      // 'bold',
      // 'italic',
      // 'underline',
      // 'strikeThrough',
      // 'subscript',
      // 'superscript',
      // 'justifyLeft',
      // 'justifyCenter',
      // 'justifyRight',
      'justifyFull',
      // 'indent',
      // 'outdent',
      // 'insertUnorderedList',
      // 'insertOrderedList',
      'heading',
      // 'fontName'
    ],
    [
      // 'fontSize',
      // 'textColor',
      // 'backgroundColor',
      'customClasses',
      // 'link',
      // 'unlink',
      'insertImage',
      'insertVideo',
      'insertHorizontalRule',
      // 'removeFormat',
      'toggleEditorMode'
    ]
  ]
};
