import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CoreModule } from '@ff/core';
import { LoaderModule } from '@ff/loader';
import { TranslateModule } from '@ngx-translate/core';
import { PaginatorServerSideComponent } from './components/paginator-server-side/paginator-server-side.component';
import { MaterialModule } from './material.module';

@NgModule({
    declarations: [
        PaginatorServerSideComponent
    ],
    providers: [
        // { provide: MatPaginatorIntl, useClass: CustomMatPaginatorIntl }
        DatePipe
    ],
    imports: [
        // Angular
        ReactiveFormsModule,
        CommonModule,
        // FF Packages
        LoaderModule,
        CoreModule,
        // Angular Material
        MaterialModule,

        // Others
        TranslateModule,
    ],
    exports: [
        PaginatorServerSideComponent,

        ReactiveFormsModule,
        // FF Packages
        LoaderModule,
        CoreModule,
        // Angular Material
        MaterialModule,

        // Others
        TranslateModule,
    ]
})
export class SharedModule {
    public constructor() { }
}
