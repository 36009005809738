import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { LoaderService } from '@ff/loader';
import { TranslateService } from '@ngx-translate/core';
import { BasicCodedObject } from 'src/app/models/basic-coded-object.model';
import { BasicLabelledObject } from 'src/app/models/basic-labelled-object.model';
import { absoluteRoutesNames } from 'src/app/modules/shared/absolute.routes';
import { UtilServiceService } from 'src/app/modules/shared/services/util-service.service';
import { StationsList } from '../../../../models/stations-list.model';
import { BasicObjectService } from './../../services/basic-object.service';
import { StationService } from './../../services/station.service';

@Component({
  selector: 'app-stations',
  templateUrl: './stations.component.html',
  styleUrls: ['./stations.component.scss']
})
export class StationsComponent implements OnInit, AfterViewInit {

  contactFunctionsFormGroup: FormGroup;
  providerTypesFormGroup: FormGroup;
  contactFunctions: BasicLabelledObject[];
  providerTypes: BasicCodedObject[];

  stationsDataSource: MatTableDataSource<StationsList> = new MatTableDataSource([]);
  sortedStations: StationsList[];
  displayedColumns = [
    'status', 'code_implant', 'brand', 'sales_channel', 'site_name', 'city', 'client_name', 'actions'
  ];

  searchForm: FormGroup;
  stationForm: FormGroup;

  stationsPaginationItem = {
    total: 0,
    next_page: null,
    previous_page: null
  };

  @ViewChild('stationsTableContainer') stationsTableContainer: ElementRef;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  messages = {
    done: 'Votre fichier est en cours de préparation. Vous recevrez par mail un lien de téléchargement lorsque le fichier sera prêt. \n' +
        'Your file is being prepared. You will receive an email with a link to download it when the file is ready.',
    close: 'Close'
  };

  constructor(
    private fb: FormBuilder,
    private stationService: StationService,
    private basicObjectService: BasicObjectService,
    private loader: LoaderService,
    private router: Router,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private utilService: UtilServiceService
  ) { }

  ngOnInit(): void {
    this.initForms();
    this.initData();
  }

  ngAfterViewInit(): void {
  }

  exportStations2(): void {
    this.loader.show();
    /*this.stationService.export().subscribe(
      (res: Blob) => {
        this.loader.hide();
        this.utilService.exportXlsx(res, 'stations');
      },
      (error) => { this.loader.hide(); },
      () => { this.loader.hide(); }
    );*/
  }

  exportStations(): void  {
    loaderShow(this.loader);
    this.stationService.export().subscribe(
      (response: any) => {
        loaderHide(this.loader);
        this.snackBar.open(
            this.messages.done,
            this.messages.close,
            { duration: 5000, panelClass: ['errors-import']  }
        );
      },
      err => {
        loaderHide(this.loader);
        console.log(err);
      }
    );
  }


  updateSelectedContactFunctions(): void {
    this.stationService.updateSelectedContactFunctions(this.contactFunctionsFormGroup.value).subscribe(() => {
      this.snackBar.open(this.translate.instant('general.messages.data_saved'));
    });
  }

  updateSelectedProviderTypes(): void {
    this.stationService.updateSelectedProviderTypes(this.providerTypesFormGroup.value).subscribe(() => {
      this.snackBar.open(this.translate.instant('general.messages.data_saved'));
    });
  }

  clearFilter(): void {
    this.searchForm.reset();
    this.filterStations();
  }

  filterStations(): void {
    this.paginator.firstPage();
    this.getStations(1);
  }

  sortData(): void {
    this.paginator.firstPage();
    this.getStations(1);
  }

  deleteStation(stationId: number): void {
    // TODO ?
  }

  gotoStationCard(stationId?: number): void {
    this.router.navigate([absoluteRoutesNames.STATIONS, stationId || absoluteRoutesNames.ADD]);
  }

  paginationChanged(event): void {
    const direction = (event.pageIndex > event.previousPageIndex) ? 'next' : 'previous';
    const page = this.stationsPaginationItem[`${direction}_page`];
    this.getStations(page);
  }

  private initForms(): void {
    this.contactFunctionsFormGroup = this.fb.group({
      selected_contact_functions_ids: []
    });

    this.providerTypesFormGroup = this.fb.group({
      selected_provider_types_ids: []
    });

    this.searchForm = this.fb.group({
      query: [null]
    });
  }

  private initData(): void {
    this.getStationsContacts();
    this.getProviderTypes();
    this.getStations(1);
  }

  private getStationsContacts(): void {
    this.basicObjectService.getAll('contact_functions').subscribe((contactFunctions: BasicLabelledObject[]) => {
      this.contactFunctions = contactFunctions;
      const selectedIds = this.contactFunctions.filter(cf => cf.is_displayed).map(cf => cf.id);
      this.contactFunctionsFormGroup.get('selected_contact_functions_ids').setValue(selectedIds);
    });
  }

  private getProviderTypes(): void {
    this.basicObjectService.getTypes().subscribe((providerTypes: BasicCodedObject[]) => {
      this.providerTypes = providerTypes;
      const selectedIds = this.providerTypes.filter(pt => pt.is_displayed).map(pt => pt.id);
      this.providerTypesFormGroup.get('selected_provider_types_ids').setValue(selectedIds);
    });
  }

  private getStations(page: number): void {
    if (!this.loader.isVisible()) { this.loader.show(); }

    const sort = this.getSort();
    const filter = this.getFilter();

    this.stationService.getAll({ page, sort, filter }).subscribe(
      (data: any[]) => {
        this.stationsDataSource.data = data['stations'];

        this.stationsPaginationItem.total = data['total'];
        this.stationsPaginationItem.previous_page = this.getPageIndex(data['previous_page']);
        this.stationsPaginationItem.next_page = this.getPageIndex(data['next_page']);

        this.stationsTableContainer.nativeElement.scrollTop = 0;
      },
      (error) => { this.loader.hide(); },
      () => { this.loader.hide(); }
    );
  }

  private getPageIndex(page?: string): number {
    if (!page) { return 0; }

    const pageAttributeName = 'page=';

    return +page.substring(page.indexOf(pageAttributeName) + pageAttributeName.length, page.length);
  }

  private getFilter(): string | null {
    const filterValue = this.searchForm.value.query || '';
    let filter = filterValue.trim();

    if (filter.length > 0 && filter.length < 3) {
      return;
    }

    if (filter.length === 0) {
      filter = null;
    }
    return filter;
  }

  private getSort(): { active: string, direction: string } {
    if (!this.sort) {
      return { active: 'status', direction: 'asc' };
    }

    return { active: this.sort.active, direction: this.sort.direction };
  }

  public synchStationsStatus(): void {
    if (!this.loader.isVisible()) { this.loader.show(); }

    this.stationService.synchStations().subscribe(
      res => {
        if (res.data != 0) {
          this.initData()
        }
        this.loader.hide()
        this.snackBar.open(res.message);
      }, 
      err => {
        this.loader.hide()
        this.snackBar.open(err);
      }
    );
  }
}
function loaderShow(_loaderService: LoaderService) {
  if (!_loaderService.isVisible()) {
    _loaderService.show();
  }
}

function loaderHide(_loaderService: LoaderService) {
  if (_loaderService.isVisible()) {
    _loaderService.hide();
  }
}

