<div mat-dialog-title>
  <h3>{{'users.delete.confirm.title' | translate}}
  </h3>
  <mat-icon color="primary" mat-dialog-close>close</mat-icon>
</div>

<div class="mb-2">
  <p>{{'users.delete.confirm.text' | translate: {last_name: userAcount.last_name || '', first_name:
    userAcount.first_name || ''} }}</p>
</div>

<div class="buttons-bar">
  <button mat-flat-button type="button" class="mr-3" mat-dialog-close>
    <span class="btn-text">
      {{'general.buttons.cancel' | translate}}
    </span>
  </button>
  <button mat-flat-button color="primary" (click)="confirmDeletion()">
    <span class="btn-text">
      {{'general.buttons.validate' | translate}}
    </span>
  </button>
</div>
