import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Incident } from 'src/app/models/incident.model';
import { endpoints } from 'src/environments/endpoints';
import { environment } from 'src/environments/environment';
import { ErrorHandlerService } from '../../shared/services/error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class IncidentService {
  constructor(
    private http: HttpClient,
    private errorHandlerService: ErrorHandlerService
  ) { }

  get(incidentId: number): Observable<Incident> {
    return this.http.get(`${environment.api.host}${endpoints.incidents}/${incidentId}`).pipe(
      map(
        (res: any) => new Incident(res.data.incident)
      ),
      catchError(err => this.errorHandlerService.handleError(err))
    );
  }

  getAll(
    params: {
      page: number,
      sort: { active: string, direction: string },
      searchInput: { query: string, date_start: string, date_end: string }
    }): Observable<Incident[]> {

    let url = `${environment.api.host}${endpoints.incidents}`
      + `?page=${params.page}&sort_active=${params.sort.active}&sort_direction=${params.sort.direction}`;
    if (params.searchInput.query) { url += `&query=${params.searchInput.query}`; }
    if (params.searchInput.date_start) { url += `&date_start=${params.searchInput.date_start}`; }
    if (params.searchInput.date_end) { url += `&date_end=${params.searchInput.date_end}`; }

    return this.http.get(url).pipe(
      map(
        (res: any) => {
          res.data['incidents'] = res.data.incidents.map(incident => new Incident(incident));
          return res.data;
        }
      ),
      catchError(err => this.errorHandlerService.handleError(err))
    );
  }

  moderateComment(incidentId: number, moderatedComment: string): Observable<void> {
    return this.http.patch(
      `${environment.api.host}${endpoints.incidents}/${incidentId}/comment`, {
      new_comment: moderatedComment
    }).pipe(
      map(() => null),
      catchError(err => this.errorHandlerService.handleError(err))
    );
  }

  export(): Observable<Blob> {
    return this.http.post(environment.api.host + endpoints.incidents + endpoints.export,
      null,
      { responseType: 'blob' }
    ).pipe(
      map(res => res),
      catchError(err => this.errorHandlerService.handleError(err))
    );
  }

  getFile(token) {
    const url = environment.api.host + '/getGeneratedFile/' + token;
    return this.http.get(url, {responseType: 'blob', observe: 'response'});
  }

}
