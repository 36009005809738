import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Matrice } from 'src/app/models/matrice.model';

@Component({
  selector: 'app-confirm-delete-matrice-dialog',
  templateUrl: './confirm-delete-matrice-dialog.component.html',
  styleUrls: ['./confirm-delete-matrice-dialog.component.scss']
})
export class ConfirmDeleteMatriceDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ConfirmDeleteMatriceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public matriceData?: Matrice
  ) { }

  ngOnInit(): void {
  }

  confirmDeletion(): void {
    this.dialogRef.close({
      confirm: true
    });
  }
}
