import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { LoaderService } from '@ff/loader';
import { Matrice } from 'src/app/models/matrice.model';
import { absoluteRoutesNames } from 'src/app/modules/shared/absolute.routes';
import { UtilServiceService } from 'src/app/modules/shared/services/util-service.service';
import { MatriceService } from '../../services/matrice.service';
import { ConfirmDeleteMatriceDialogComponent } from './confirm-delete-matrice-dialog/confirm-delete-matrice-dialog.component';

@Component({
  selector: 'app-matrices',
  templateUrl: './matrices.component.html',
  styleUrls: ['./matrices.component.scss']
})
export class MatricesComponent implements OnInit, AfterViewInit {

  matricesDataSource: MatTableDataSource<Matrice> = new MatTableDataSource([]);
  sortedMatrices: Matrice[];
  displayedColumns = ['type', 'title', 'actions'];

  searchForm: FormGroup;
  matriceForm: FormGroup;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private fb: FormBuilder,
    private matriceService: MatriceService,
    private loader: LoaderService,
    private dialog: MatDialog,
    private router: Router,
    private utilService: UtilServiceService
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.initData();
  }

  ngAfterViewInit(): void {
    this.matricesDataSource.paginator = this.paginator;
    this.matricesDataSource.sort = this.sort;
    this.matricesDataSource.sortData = this.sortData.bind(this);
  }

  exportMatrices(): void {
    this.loader.show();
    this.matriceService.export().subscribe(
      (res: Blob) => {
        this.loader.hide();
        this.utilService.exportXlsx(res, 'matrices');
      },
      (error) => { this.loader.hide(); },
      () => { this.loader.hide(); }
    );
  }

  clearFilter(): void {
    this.searchForm.reset();
    this.filterMatrices();
  }

  filterMatrices(): void {
    const filterValue = this.searchForm.value.query || '';
    this.matricesDataSource.filter = filterValue.trim().toLowerCase();

    if (this.matricesDataSource.paginator) {
      this.matricesDataSource.paginator.firstPage();
    }
  }

  deleteMatrice(matrice: Matrice): void {
    const dialogRef = this.dialog.open(ConfirmDeleteMatriceDialogComponent, {
      data: matrice,
      width: '45rem'
    });

    dialogRef.afterClosed().subscribe(data => {
      if (!data) { return; }

      this.matriceService.delete(matrice.id).subscribe(() => {
        this.getMatrices();
      });
    });
  }

  sortData(data: Matrice[], sort: Sort): Matrice[] {
    if (!sort.active || sort.direction === '') {
      return data;
    }

    return data.sort((a: Matrice, b: Matrice) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'description': return this.compare(a.incident_type.description, b.incident_type.description, isAsc);
        case 'type': return this.compare(a.incident_type.type, b.incident_type.type, isAsc);
        default: return 0;
      }
    });
  }

  gotoMatriceCard(matriceId?: number): void {
    this.router.navigate([absoluteRoutesNames.MATRICES, matriceId || absoluteRoutesNames.ADD]);
  }

  private initForm(): void {
    this.searchForm = this.fb.group({
      query: [null]
    });
  }

  private initData(): void {
    this.getMatrices();
  }

  private getMatrices(): void {
    if (!this.loader.isVisible()) { this.loader.show(); }

    this.matriceService.getAll().subscribe(
      (matrices: Matrice[]) => {
        this.matricesDataSource.data = matrices;
        this.matricesDataSource.filterPredicate = this.buildTableFilter().bind(this);
      },
      (error) => { this.loader.hide(); },
      () => { this.loader.hide(); }
    );
  }

  private buildTableFilter(): (matrice: Matrice, filter: string) => boolean {
    const filterFunction = (matrice: Matrice, filter: string): boolean => {
      if (!filter || filter.trim().toLocaleLowerCase().length < 1) {
        return true;
      }

      const searchTerm = filter.trim().toLowerCase();

      return matrice.incident_type.type?.toLowerCase().indexOf(searchTerm) !== -1
        || matrice.incident_type?.description?.toLowerCase().indexOf(searchTerm) !== -1;
    };

    return filterFunction;
  }

  private compare(a: number | string, b: number | string, isAsc: boolean): number {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
}
