<div mat-dialog-title>
  <h3>{{'matrices.delete.confirm.title' | translate}}
  </h3>
  <mat-icon color="primary" mat-dialog-close>close</mat-icon>
</div>

<div class="mb-2">
  <p>{{'matrices.delete.confirm.text' | translate: {type: matriceData.incident_type.type, description:
    matriceData.incident_type.description} }}</p>
</div>

<div class="buttons-bar">
  <button mat-flat-button type="button" class="mr-3" mat-dialog-close>
    <span class="btn-text">
      {{'general.buttons.cancel' | translate}}
    </span>
  </button>
  <button mat-flat-button color="primary" (click)="confirmDeletion()">
    <span class="btn-text">
      {{'general.buttons.validate' | translate}}
    </span>
  </button>
</div>
