<div class="main-content">
  <h2 class="text-center">
    {{(matriceId? 'matrices.matrice_card.title_update' : 'matrices.matrice_card.title_create') | translate}}
  </h2>

  <form #matriceFormElt class="create-matrice-form mt-3" [formGroup]="matriceForm"
    (ngSubmit)="submitMatrice(matriceForm)" novalidate *ngIf="matriceForm">

    <ng-container formGroupName="incident_type">
      <h3>{{'matrices.matrice_card.properties' | translate}}</h3>
      <mat-card>
        <mat-card-content>
          <mat-form-field class="w-auto">
            <mat-label>{{'matrices.matrice_card.type' | translate}}</mat-label>
            <mat-select formControlName="type">
              <mat-option *ngFor="let matriceType of matriceTypes" [value]="matriceType">
                {{matriceType}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{'matrices.matrice_card.title' | translate}}</mat-label>
            <input matInput formControlName="description" maxlength="100">
            <mat-hint>{{'matrices.matrice_card.title_hint' | translate: {number: getMatriceTitleLength()} }}</mat-hint>
          </mat-form-field>
        </mat-card-content>
      </mat-card>

      <h3 class="mt-5">{{'entities.incident_types.reflex_sheet' | translate}}</h3>
      <mat-card>
        <mat-card-content>
          <div class="angular-editor-container">
            <angular-editor formControlName="reflex_sheet" [config]="editorConfig"></angular-editor>
          </div>
        </mat-card-content>
      </mat-card>
    </ng-container>

    <h3 class="mt-5">{{'matrices.matrice_card.severity_levels.title' | translate}}</h3>
    <mat-card>
      <mat-card-content>
        <ng-container formArrayName="matrice_severity_levels" *ngIf="matriceSeverityLevelsArray">
          <mat-expansion-panel *ngFor="let matriceSeverityLevel of matriceSeverityLevelsArray.controls; let i=index">
            <mat-expansion-panel-header>
              <h3 [ngStyle]="{'color': matriceSeverityLevel.value.hex_color}" class="mb-0">
                {{'matrices.matrice_card.severity_levels.level.title' | translate: {level:
                (matriceSeverityLevel.value.level + 1)} }}
                - {{'entities.severity_levels.' + matriceSeverityLevel.value.code | translate }}
              </h3>
            </mat-expansion-panel-header>
            <div class="panel-content py-3" [formGroupName]="i">
              <mat-form-field>
                <mat-label>{{'matrices.matrice_card.severity_levels.level.description' | translate}}</mat-label>
                <textarea matInput formControlName="description" maxlength="500"></textarea>
                <mat-hint>{{'matrices.matrice_card.severity_levels.level.description_hint' | translate: {number:
                  getLevelDescriptionLength(i)} }}</mat-hint>
              </mat-form-field>

              <div class="contacts-list mt-3" formArrayName="matrice_contact_functions">
                <ng-container *ngFor="let contact of getMatriceContactFunctionsArray(i).controls; let j=index">
                  <div [formGroupName]="j" class="card-infos">
                    <mat-form-field class="w-auto">
                      <mat-label>{{'matrices.matrice_card.severity_levels.level.contact' | translate}}</mat-label>
                      <mat-select [formControlName]="'contact_function_id'">
                        <mat-option *ngFor="let contactFunction of contactFunctions" [value]="contactFunction.id"
                        [disabled]="isOptionAlreadyChosenForLevel(matriceSeverityLevel, contactFunction.id)">
                          {{contactFunction.label}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field class="w-auto">
                      <mat-label>{{'matrices.matrice_card.severity_levels.level.contact_type' | translate}}</mat-label>
                      <mat-select [formControlName]="'contact_type'">
                        <mat-option *ngFor="let contactTypes of contactTypes" [value]="contactTypes">
                          {{'matrices.matrice_card.severity_levels.level.contact_types.'+contactTypes | translate}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <div class="pt-2 d-flex justify-content-start">
                      <button mat-icon-button color="primary" (click)="removeMatriceContact(i,j)">
                        <mat-icon>delete</mat-icon>
                      </button>
                    </div>
                  </div>
                </ng-container>

                <button mat-flat-button color="primary" type="button" (click)="addMatriceContact(i)">
                  <mat-icon class="mr-1">add</mat-icon>{{'matrices.matrice_card.severity_levels.level.add_contact' |
                  translate}}
                </button>
              </div>
            </div>
          </mat-expansion-panel>
        </ng-container>
      </mat-card-content>
    </mat-card>
  </form>

  <div class="page-actions mt-3">
    <button mat-flat-button class="mr-3" (click)="gotoMatrices()">{{'general.buttons.cancel' | translate}}</button>
    <button mat-flat-button color="primary" (click)="submitMatrice(matriceForm)">
      {{'general.buttons.validate' | translate}}
    </button>
  </div>
</div>
