import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoaderService } from '@ff/loader';
import { Station } from 'src/app/models/station.model';
import { ContactService } from '../../../services/contact.service';
import { StationService } from '../../../services/station.service';

@Component({
  selector: 'app-linked-stations-dialog',
  templateUrl: './linked-stations-dialog.component.html',
  styleUrls: ['./linked-stations-dialog.component.scss']
})
export class LinkedStationsDialogComponent implements OnInit {

  linkedStations: Station[] = [];
  newStationCodeImplant = 'NF';
  newStationsDepartment: string;
  newStationsSalesChannel: string;

  constructor(
    private stationService: StationService,
    private contactService: ContactService,
    private loader: LoaderService,
    public dialogRef: MatDialogRef<LinkedStationsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public contactId: number
  ) { }

  ngOnInit(): void {
    this.loader.show();
    this.contactService.getLinkedStations(this.contactId).subscribe(
      (stations: Station[]) => {
        this.linkedStations = stations;
        this.loader.hide();
      },
      (error) => { this.loader.hide(); },
      () => { this.loader.hide(); }
    );
  }

  addStation(): void {
    this.stationService.getByCodeImplant(this.newStationCodeImplant).subscribe(
      (station: Station) => {
        this.linkedStations.push(station);
        this.linkedStations.sort((stA, stB) => {
          return stA.code_implant.localeCompare(stB.code_implant);
        });
        this.newStationCodeImplant = 'NF';
        this.submitLinkedStations();
      },
      (error) => { },
      () => { }
    );
  }

  updateStationsByDepartments(isForRemove = false): void {
    const departments = this.newStationsDepartment? this.newStationsDepartment.split(',') : [];
    const salesChannel = this.newStationsSalesChannel;
    this.loader.show();
    this.contactService.updateLinkedStationsByDepartments(this.contactId, departments, salesChannel, isForRemove).subscribe(
      (stations: Station[]) => {
        this.newStationsDepartment = '';
        this.newStationsSalesChannel = '';
        this.linkedStations = stations;
        this.loader.hide();
      },
      (error) => { this.loader.hide(); },
      () => { this.loader.hide(); }
    );
  }

  updateAllStations(isForRemove = false): void {
    this.loader.show();
    this.contactService.updateAllLinkedStations(this.contactId, isForRemove).subscribe(
      (stations: Station[]) => {
        this.newStationsDepartment = '';
        this.linkedStations = stations;
        this.loader.hide();
      },
      (error) => { this.loader.hide(); },
      () => { this.loader.hide(); }
    );
  }

  isStationAlreadyInList(): boolean {
    return this.linkedStations.find(st => st.code_implant === this.newStationCodeImplant) !== undefined;
  }

  canAddStation(): boolean {
    return this.newStationCodeImplant.length === 8 && !this.isStationAlreadyInList();
  }

  canAddStationSC(): boolean {
    return this.newStationsSalesChannel?.length > 1 && !this.isStationAlreadyInList();
  }

  removeStation(index: number): void {
    this.linkedStations.splice(index, 1);
    this.submitLinkedStations();
  }

  private submitLinkedStations(): void {
    this.loader.show();
    const linkedStationsIds = this.linkedStations.map(ls => ls.id);
    this.contactService.updateLinkedStations(this.contactId, linkedStationsIds).subscribe(
      (stations: Station[]) => {
        this.linkedStations = stations;
        this.loader.hide();
      },
      (error) => { this.loader.hide(); },
      () => { this.loader.hide(); }
    );
  }
}
