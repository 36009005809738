<div mat-dialog-title>
  <h3>{{('providers.linked_stations.title') | translate: {count: linkedStations.length} }}</h3>
  <mat-icon color="primary" mat-dialog-close>close</mat-icon>
</div>

<mat-dialog-content>
  <div class="linked-stations mt-3" *ngIf="linkedStations.length > 0; else noLinkedStationsTemplate">
    <div class="linked-station" *ngFor="let station of linkedStations; let index=index;">
      <div class="infos">
        <div>{{station.code_implant}}</div>
        <div>{{station.info.site_name}}</div>
      </div>
      <div>
        <mat-icon color="primary" class="pointer" (click)="removeStation(index)">clear</mat-icon>
      </div>
    </div>
  </div>
  <ng-template #noLinkedStationsTemplate>
    <div class="linked-stations mt-3 mb-4">
      {{'providers.linked_stations.no_data' | translate}}
    </div>
  </ng-template>

  <div class="d-flex align-items-baseline">
    <div class="add-item-container">
      <mat-form-field class="mr-3">
        <mat-label>{{'providers.linked_stations.multi_dep' | translate}}</mat-label>
        <input matInput [(ngModel)]="newStationsDepartment">
      </mat-form-field>
      <button mat-flat-button color="primary" (click)="updateStationsByDepartments()" class="mr-3"
        [disabled]="newStationsDepartment?.length < 2">
        {{'general.buttons.add' | translate}}</button>
        <button mat-flat-button color="primary" (click)="updateStationsByDepartments(true)"
          [disabled]="newStationsDepartment?.length < 2">
          {{'general.buttons.remove' | translate}}</button>
    </div>
  </div>

  <div class="d-flex align-items-baseline">
    <div class="add-item-container">
      <mat-form-field class="mr-3">
        <mat-label>{{'entities.stations.code_implant' | translate}}</mat-label>
        <input matInput [(ngModel)]="newStationCodeImplant" maxlength="8">
        <mat-hint class="mat-error" *ngIf="isStationAlreadyInList()">{{'providers.linked_stations.already_in_list' |
          translate}}</mat-hint>
      </mat-form-field>
      <button mat-flat-button color="primary" (click)="addStation()" [disabled]="!canAddStation()">
        {{'general.buttons.add' | translate}}</button>
    </div>

    <button mat-icon-button (click)="updateAllStations()" color="primary" class="mr-3">
      <mat-icon color="primary">playlist_add</mat-icon>
    </button>
    <button mat-icon-button (click)="updateAllStations(true)" color="primary">
      <mat-icon color="primary">playlist_remove</mat-icon>
    </button>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="buttons-bar mt-0">
  <button mat-flat-button color="primary" mat-dialog-close>{{'general.buttons.close' | translate}}</button>
</mat-dialog-actions>
