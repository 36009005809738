import { BasicLabelledObject } from './basic-labelled-object.model';

// tslint:disable:variable-name
export class StationInfo {

  id: number;
  info: StationInfo;
  brand_id: number;
  brand: BasicLabelledObject;
  network: BasicLabelledObject;
  sales_channel_id: number;
  sales_channel: BasicLabelledObject;
  segmentation_id: number;
  segmentation: BasicLabelledObject;
  sca_id: number;
  sca: BasicLabelledObject;
  site_name: string;
  street: string;
  city: string;
  zipcode: string;
  client_code: string;
  client_name: string;
  establishment_date: string;
  email: string;
  phone: string;
  manager_last_name: string;
  manager_first_name: string;
  manager_phone: string;
  manager_2_last_name: string;
  manager_2_first_name: string;
  manager_2_phone: string;
  status_start_date: string;
  status_end_date: string;
  department: number;
  department_name: string;
  longitude: string;
  latitude: string;
  station_map: string;
  sensitivity_sheet: string;

  created_at: string;
  updated_at: string;


  // sla: string;


  constructor(data = {}) {
    Object.assign(this, data);

    this.brand = new BasicLabelledObject(this.brand);
    this.network = new BasicLabelledObject(this.network);
    this.sales_channel = new BasicLabelledObject(this.sales_channel);
    this.segmentation = new BasicLabelledObject(this.segmentation);
    this.sca = new BasicLabelledObject(this.sca);
  }
}
