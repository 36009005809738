import { BasicCodedObject } from './basic-coded-object.model';
import { ClickToCall } from './clicktocall.model';
import { IncidentType } from './incident-type.model';
import { SeverityLevel } from './severity-level.model';
import { Station } from './station.model';
import { User } from './user.model';

// tslint:disable:variable-name
export class Incident {

  id: number;
  station_id: number;
  user_id?: number;
  incident_type_id: number;
  severity_level_id: number;
  status_id: number;
  declaration_date: string;
  declaration_time: string;
  comment: string;
  created_at: string;
  updated_at: string;

  station: Station;
  user?: User;
  incident_type: IncidentType;
  severity_level?: SeverityLevel;
  status: BasicCodedObject;

  contact: string;
  contact_type: string;
  contact_name: string;
  //clicktocall: ClickToCall

  constructor(data = {}) {
    Object.assign(this, data);
    this.station = new Station(this.station);
    this.user = this.user ? new User(this.user) : this.user;
    this.incident_type = new IncidentType(this.incident_type);
    this.severity_level = new SeverityLevel(this.severity_level);
    this.status = new BasicCodedObject(this.status);
    //this.clicktocall = new ClickToCall(this.clicktocall);
  }
}
