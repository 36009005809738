import { Component, OnInit } from '@angular/core';
import { AuthService } from '@ff/auth';
import { UserService } from '@ff/user';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(
    public userService: UserService,
    public authService: AuthService
  ) {
  }

  ngOnInit(): void {
  }


}
