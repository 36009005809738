<div class="alert alert-warning mx-5 mb-3" [innerText]="'repositories.tabs.types.warning' | translate">
</div>

<form [formGroup]="typesForm" novalidate>
  <mat-card>
    <mat-card-content>
      <div class="basic-objects-list" formArrayName="types">
        <div class="basic-object" *ngFor="let type of typesArray.controls">
          <ng-container [formGroup]="type">
            <mat-form-field>
              <mat-label>{{'repositories.tabs.code' | translate}}</mat-label>
              <input matInput formControlName="code" class="basic-object-input">
              <div class="d-flex ml-2" matSuffix>
                <mat-icon class="pointer mr-2" color="primary" (click)="switchTypeEdit(type)" *ngIf="type.disabled">edit
                </mat-icon>
                <mat-icon class="pointer" color="primary" (click)="deleteType(type)" *ngIf="type.disabled">
                  delete
                </mat-icon>
                <mat-icon class="pointer mr-2" color="primary" (click)="saveType(type)" *ngIf="type.enabled">
                  check</mat-icon>
                <mat-icon class="pointer" color="primary" (click)="switchTypeEdit(type)" *ngIf="type.enabled">
                  clear</mat-icon>
              </div>
            </mat-form-field>
          </ng-container>
        </div>
      </div>
    </mat-card-content>
    <mat-card-actions>
      <button mat-flat-button color="primary" (click)="addType()">
        <mat-icon>add</mat-icon>{{'repositories.tabs.types.add' | translate}}
      </button>
    </mat-card-actions>
  </mat-card>
</form>
