import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-paginator-server-side',
  templateUrl: './paginator-server-side.component.html',
  styleUrls: ['./paginator-server-side.component.scss']
})
export class PaginatorServerSideComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
