import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { validateIgg } from 'src/app/modules/shared/validators/igg.validator';
import { BasicCodedObject } from '../../../../../models/basic-coded-object.model';
import { User } from '../../../../../models/user.model';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-user-create-update-dialog',
  templateUrl: './user-create-update-dialog.component.html',
  styleUrls: ['./user-create-update-dialog.component.scss']
})
export class UserCreateUpdateDialogComponent implements OnInit {

  isNewUser: boolean;
  roles: BasicCodedObject[];

  userForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    public dialogRef: MatDialogRef<UserCreateUpdateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public userData?: User
  ) { }

  ngOnInit(): void {
    this.isNewUser = this.userData === undefined;
    this.initForm();
    this.initData();
  }

  submitUser(userForm: FormGroup): void {
    if (!userForm.valid) { return; }

    this.dialogRef.close({
      data: userForm.value
    });
  }

  isVisitorUser(): boolean {
    return this.userData ? this.userData?.role.code.toLowerCase() === 'visitor' : false;
  }

  private initData(): void {
    this.getRoles();
  }

  private initForm(): void {
    const formData = new User(this.userData);

    this.userForm = this.fb.group({
      id: formData.id,
      last_name: [formData.last_name, Validators.required],
      first_name: [formData.first_name, Validators.required],
      email: [formData.email, Validators.required],
      igg: [formData.igg, [validateIgg()]],
      role_id: [formData.role.id, Validators.required]
    });

    if (!this.isNewUser) {
      // this.userForm.get('igg').disable();
      this.userForm.get('email').disable();
    }
  }

  private getRoles(): void {
    this.userService.getRoles().subscribe((roles: BasicCodedObject[]) => {
      this.roles = roles;
    });
  }
}
