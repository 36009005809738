import { Routes } from '@angular/router';
import { AppAdminGuard } from 'src/app/guards/app-admin.guard';
import { MyAccountComponent } from './components/my-account/my-account.component';

export const routes: Routes = [
  {
    path: 'user',
    children: [
      { path: 'my-account', component: MyAccountComponent, canActivate: [AppAdminGuard] }
    ]
  }
];

