<div mat-dialog-title>
  <h3>{{ 'general.messages.confirm_delete' | translate}}</h3>
  <mat-icon color="primary" mat-dialog-close>close</mat-icon>
</div>

<mat-dialog-content>
  <p class="my-3" [innerHtml]="'general.messages.confirm_delete_message' | translate: {name: getName() }"></p>
</mat-dialog-content>

<mat-dialog-actions class="buttons-bar">
  <button mat-flat-button mat-dialog-close>{{'general.buttons.cancel' | translate}}</button>
  <button mat-flat-button color="primary" [mat-dialog-close]="'confirm'">
    {{'general.buttons.validate' | translate}}
  </button>
</mat-dialog-actions>
