<div mat-dialog-title>
  <h3>{{(isNewProvider? 'providers.create.title' : 'providers.update.title') | translate}}</h3>
  <mat-icon color="primary" mat-dialog-close>close</mat-icon>
</div>

<mat-dialog-content>
  <form class="create-provider-form" (ngSubmit)="submitProvider(providerForm)" [formGroup]="providerForm" novalidate>
    <div class="mat-form-slide-toggle">
      <mat-slide-toggle
          class="example-margin"
          color="warn"
          formControlName="role_visitor_dcm">
          {{'entities.providers.roleVisitorDCM' | translate}}
      </mat-slide-toggle>
    </div>
    <div class="mat-form-slide-toggle"></div>
    <div class="mat-form-slide-toggle"></div>
    <mat-form-field>
      <mat-label>{{'entities.providers.company' | translate}}</mat-label>
      <input matInput formControlName="company">
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{'entities.providers.role' | translate}}</mat-label>
      <mat-select formControlName="role">
        <mat-option value="CAR">CAR</mat-option>
        <mat-option value="COM">COM</mat-option>
        <mat-option value="DCM">DCM</mat-option>
      </mat-select>
    </mat-form-field>
    
    <mat-form-field>
      <mat-label>{{'entities.providers.provider_type' | translate}}</mat-label>
      <mat-select formControlName="provider_type_id">
        <mat-option *ngFor="let providerType of providerTypes" [value]="providerType.id">
          {{'entities.provider_types.' + providerType.code | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{'entities.providers.phone_1' | translate}}</mat-label>
      <input matInput formControlName="phone_1">
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{'entities.providers.phone_2' | translate}}</mat-label>
      <input matInput formControlName="phone_2">
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{'entities.providers.phone_3' | translate}}</mat-label>
      <input matInput formControlName="phone_3">
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{'entities.providers.email' | translate}}</mat-label>
      <input matInput formControlName="email">
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{'entities.providers.email_2' | translate}}</mat-label>
      <input matInput formControlName="email_2">
    </mat-form-field>
    <div></div>
    <mat-form-field>
      <mat-label>{{'entities.providers.opening_hours' | translate}}</mat-label>
      <input matInput formControlName="opening_hours">
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{'entities.providers.geographic_scope' | translate}}</mat-label>
      <input matInput formControlName="geographic_scope">
    </mat-form-field>
    
    
  </form>
</mat-dialog-content>

<mat-dialog-actions class="buttons-bar">
  <button mat-flat-button mat-dialog-close>{{'general.buttons.cancel' | translate}}</button>
  <button mat-flat-button color="primary" (click)="submitProvider(providerForm)">
    {{'general.buttons.validate' | translate}}
  </button>
</mat-dialog-actions>
