import { BasicCodedObject } from './basic-coded-object.model';

// tslint:disable:variable-name
export class StationsList {

  id: number;
  code_implant: string;
  status: BasicCodedObject;
  brand: string;
  sales_channel: string;
  site_name: string;
  city: string;
  client_name: string;

  constructor(data = {}) {
    Object.assign(this, data);
    this.status = new BasicCodedObject(this.status);
  }
}
