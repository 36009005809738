export const mainRoutesNames = {
    CONFIGURATION: 'configuration',
    CONTACTS: 'contacts',
    INCIDENTS: 'incidents',
    MATRICES: 'matrices',
    PROVIDERS: 'providers',
    REPOSITORIES: 'repositories',
    STATIONS: 'stations',
    STATION_CARD: 'stations/',
    USERS: 'users',
};
