import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Matrice } from 'src/app/models/matrice.model';
import { endpoints } from 'src/environments/endpoints';
import { environment } from 'src/environments/environment';
import { SeverityLevel } from './../../../models/severity-level.model';
import { ErrorHandlerService } from './../../shared/services/error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class MatriceService {

  constructor(
    private http: HttpClient,
    private errorHandlerService: ErrorHandlerService
  ) { }

  getAll(): Observable<Matrice[]> {
    return this.http.get(
      `${environment.api.host}${endpoints.matrices}`
    ).pipe(
      map(
        (res: any) => res.data.matrices.map(matrice => new Matrice(matrice))
      ),
      catchError(err => this.errorHandlerService.handleError(err))
    );
  }

  get(matriceId: number): Observable<Matrice> {
    return this.http.get(
      `${environment.api.host}${endpoints.matrices}/${matriceId}`
    ).pipe(
      map(
        (res: any) => new Matrice(res.data.matrice)
      ),
      catchError(err => this.errorHandlerService.handleError(err))
    );
  }

  create(matriceFormValue: any): Observable<any> {
    return this.http.post(
      `${environment.api.host}${endpoints.matrices}`, matriceFormValue
    ).pipe(
      map(
        (res: any) => res
      ),
      catchError(err => this.errorHandlerService.handleError(err))
    );
  }

  update(matriceFormValue: any): Observable<any> {
    return this.http.patch(
      `${environment.api.host}${endpoints.matrices}/${matriceFormValue.id}`, matriceFormValue
    ).pipe(
      map(
        (res: any) => res
      ),
      catchError(err => this.errorHandlerService.handleError(err))
    );
  }

  delete(matriceId: number): Observable<any> {
    return this.http.delete(
      `${environment.api.host}${endpoints.matrices}/${matriceId}`
    ).pipe(
      map(
        (res: any) => res
      ),
      catchError(err => this.errorHandlerService.handleError(err))
    );
  }

  getSeverityLevels(): Observable<SeverityLevel[]> {
    return this.http.get(
      `${environment.api.host}${endpoints.severity_levels}`
    ).pipe(
      map(
        (res: any) => res.data.severity_levels.map(severityLevel => new SeverityLevel(severityLevel))
      ),
      catchError(err => this.errorHandlerService.handleError(err))
    );
  }

  export(): Observable<Blob> {
    return this.http.post(environment.api.host + endpoints.matrices + endpoints.export,
      null,
      { responseType: 'blob' }
    ).pipe(
      map(res => res),
      catchError(err => this.errorHandlerService.handleError(err))
    );
  }

}
