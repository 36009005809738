import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoaderService } from '@ff/loader';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { Configuration } from 'src/app/models/configuration.model';
import { ConfigurationService } from '../../services/configuration.service';

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.scss']
})
export class ConfigurationComponent implements OnInit {

  configurationFormGroup: FormGroup;
  synchroFormGroup: FormGroup;

  boFileNames: any[] = [];
  foFileNames: any[] = [];

  hasFoUploadError: boolean;
  foErrorMessage: string;
  hasBoUploadError: boolean;
  boErrorMessage: string;

  constructor(
    private fb: FormBuilder,
    private configurationService: ConfigurationService,
    private loader: LoaderService,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private _cd: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.configurationService.get().subscribe(conf => {
      this.initForm(conf);
    });

    this.initTranslationFiles();
  }

  initForm(conf: Configuration): void {
    this.configurationFormGroup = this.fb.group({
      car: [conf.car, Validators.required],
      com: [conf.com, Validators.required],
      dcm: [conf.dcm, Validators.required]
    });

    this.synchroFormGroup = this.fb.group({
      nbj_sync: [conf.sync_days, Validators.required]
    })
  }

  initTranslationFiles(): void {
    this.loader.show();
    this.configurationService.getAllTranslationFileNames().subscribe(
      (allFiles) => {
        this.boFileNames = allFiles.data.bo_files;
        this.foFileNames = allFiles.data.fo_files;
        this.loader.hide();
      },
      () => {
        this.loader.hide();
      });
  }

  savePhones(): void {
    if (!this.configurationFormGroup.valid) {
      return;
    }

    this.loader.show();
    this.configurationService.update(this.configurationFormGroup.value).subscribe(
      () => {
        this.snackBar.open(this.translate.instant('general.messages.data_saved'));
      },
      (error) => { this.loader.hide(); },
      () => { this.loader.hide(); }
    );
  }

  handleSubmitNbrJrs():void {
    this.loader.show();

    this.configurationService.updateSyncDays(parseInt(this.synchroFormGroup.get('nbj_sync').value)).subscribe(
      () => {
        this.snackBar.open(this.translate.instant('general.messages.data_saved'));
      },
      (error) => { this.loader.hide(); },
      () => { this.loader.hide(); }
    );
  }

  public getFile(fileName, appId: 'bo' | 'fo'): void {
    this.loader.show();
    this.configurationService.getTranslationFile(`${appId}/${fileName}`).subscribe(
      (file) => {
        this.downloadFile(file.data);
        this.loader.hide();
      },
      () => {
        this.loader.hide();
      });
  }

  private downloadFile(file: any): void {
    const data = new Blob([file.contents], { type: 'application/json' });
    saveAs(data, file.name);
  }

  private updateTranslationFile(fileName, appId: 'bo' | 'fo', reader): void {
    const data = { name: fileName, type: 'application/json', data: reader.result };

    this.loader.show();

    if (appId === 'fo') {
      this.hasFoUploadError = false;
      this.foErrorMessage = '';
    } else {
      this.hasBoUploadError = false;
      this.boErrorMessage = '';
    }

    this.configurationService.updateTranslationFile(data, appId).subscribe(
      () => {
        this.loader.hide();
        this.snackBar.open('Le fichier de traduction a bien été mis à jour !');
      },
      (error) => {
        this.loader.hide();
        if (appId === 'fo') {
          this.hasFoUploadError = true;
          this.foErrorMessage = error.message;
          this.snackBar.open(this.foErrorMessage);
        } else {
          this.hasBoUploadError = true;
          this.boErrorMessage = error.message;
          this.snackBar.open(this.boErrorMessage);
        }
      });
  }

  private updateDocumentFile(fileName, reader): void {
    const type = fileName === 'planning' ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' : 'application/pdf';
    const data = { name: fileName, type, data: reader.result };

    this.loader.show();

    this.configurationService.updateDocumentFile(data).subscribe(
      () => {
        this.loader.hide();
        this.snackBar.open('Le document a bien été mis à jour !');
      },
      (error) => {
        this.loader.hide();
        this.snackBar.open(error.message);
      });
  }

  fileChange(event, fileName: string, appId: 'bo' | 'fo'): void {
    const reader = new FileReader();
    const fileList: FileList = event.target.files;
    if (fileList && fileList.length > 0) {
      const file: File = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.updateTranslationFile(fileName, appId, reader);
      };
      this._cd.markForCheck();
    }
  }

  docChange(event, fileName: string): void {
    const reader = new FileReader();
    const fileList: FileList = event.target.files;
    if (fileList && fileList.length > 0) {
      const file: File = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.updateDocumentFile(fileName, reader);
      };
      this._cd.markForCheck();
    }
  }
}
