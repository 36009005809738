import { ConfirmDeleteContactDialogComponent } from './confirm-delete-contact-dialog/confirm-delete-contact-dialog.component';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { LoaderService } from '@ff/loader';
import { TranslateService } from '@ngx-translate/core';
import { Contact } from './../../../../models/contact.model';
import { ContactService } from './../../services/contact.service';
import { ContactCreateUpdateDialogComponent } from './contact-create-update-dialog/contact-create-update-dialog.component';
import { LinkedStationsDialogComponent } from './linked-stations-dialog/linked-stations-dialog.component';
import { UtilServiceService } from 'src/app/modules/shared/services/util-service.service';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit, AfterViewInit {

  contactsDataSource: MatTableDataSource<Contact> = new MatTableDataSource([]);
  sortedContacts: Contact[];
  displayedColumns = ['name', 'email', 'cellphone', 'function', 'department_canal', 'region', 'actions'];

  searchForm: FormGroup;
  contactForm: FormGroup;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private fb: FormBuilder,
    private loader: LoaderService,
    private dialog: MatDialog,
    private translate: TranslateService,
    private contactService: ContactService,
    private utilService: UtilServiceService
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.initData();
  }

  ngAfterViewInit(): void {
    this.contactsDataSource.paginator = this.paginator;
    this.contactsDataSource.sort = this.sort;
    this.contactsDataSource.sortData = this.sortData.bind(this);
  }

  exportContacts(): void {
    this.loader.show();
    this.contactService.export().subscribe(
      (res: Blob) => {
        this.loader.hide();
        this.utilService.exportXlsx(res, 'contacts');
      },
      (error) => { this.loader.hide(); },
      () => { this.loader.hide(); }
    );
  }

  openCreateUpdatecontactDialog(contactData?: Contact): void {
    const dialogRef = this.dialog.open(ContactCreateUpdateDialogComponent, {
      data: contactData,
      width: '66vw'
    });

    dialogRef.afterClosed().subscribe(dialogRes => {
      if (!dialogRes?.data) { return; }

      this.loader.show();
      const request = contactData ?
        this.contactService.update(dialogRes.data) : this.contactService.create(dialogRes.data);
      request.subscribe(
        (res: any) => {
          this.getContacts();
        },
        (error) => { this.loader.hide(); },
        () => { this.loader.hide(); }
      );
    });
  }

  openLinkedStationsDialog(contactId: number): void {
    const dialogRef = this.dialog.open(LinkedStationsDialogComponent, {
      disableClose: true,
      data: contactId,
      width: '66vw'
    });
  }

  filtercontacts(): void {
    const filterValue = this.searchForm.value.query || '';
    this.contactsDataSource.filter = filterValue.trim().toLowerCase();

    if (this.contactsDataSource.paginator) {
      this.contactsDataSource.paginator.firstPage();
    }
  }

  clearFilter(): void {
    this.searchForm.reset();
    this.filtercontacts();
  }

  sortData(data: Contact[], sort: Sort): Contact[] {
    if (!sort.active || sort.direction === '') {
      return data;
    }

    return data.sort((a: Contact, b: Contact) => {
      let regionA = a.region?.toLowerCase() || '';
      let regionB = b.region?.toLowerCase() || '';

      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name': return this.compare(a.name?.toLowerCase(), b.name?.toLowerCase(), isAsc);
        case 'email': return this.compare(a.email?.toLowerCase(), b.email?.toLowerCase(), isAsc);
        case 'cellphone': return this.compare(a.cellphone?.toLowerCase(), b.cellphone?.toLowerCase(), isAsc);
        case 'department_canal': return this.compare(a.department_canal?.toLowerCase(), b.department_canal?.toLowerCase(), isAsc);
        case 'function': return this.compare(a.contact_function.label?.toLowerCase(), b.contact_function.label?.toLowerCase(), isAsc);
        case 'region': return this.compare(regionA, regionB, isAsc);
        default: return 0;
      }
    });
  }

  deleteContact(contact: Contact): void {
    const dialogRef = this.dialog.open(ConfirmDeleteContactDialogComponent, {
      data: contact,
      width: '66vw'
    });

    dialogRef.afterClosed().subscribe(dialogRes => {
      console.log(dialogRes);
      if (dialogRes !== 'confirm') { return; }

      this.contactService.delete(contact.id).subscribe(() => {
        this.getContacts();
      });
    });
  }

  private initData(): void {
    this.getContacts();
  }

  private initForm(): void {
    this.searchForm = this.fb.group({
      query: [null]
    });
  }

  private getContacts(): void {
    if (!this.loader.isVisible()) { this.loader.show(); }

    this.contactService.getAll().subscribe(
      (contacts: Contact[]) => {
        this.contactsDataSource.data = contacts;
        this.contactsDataSource.filterPredicate = this.buildTableFilter().bind(this);
      },
      (error) => { this.loader.hide(); },
      () => { this.loader.hide(); }
    );
  }

  private buildTableFilter(): (contact: Contact, filter: string) => boolean {
    const filterFunction = (contact: Contact, filter: string): boolean => {
      if (!filter || filter.trim().toLocaleLowerCase().length < 1) {
        return true;
      }

      const searchTerm = filter.trim().toLowerCase();

      return (contact.name && contact.name.toLowerCase().indexOf(searchTerm) !== -1)
        || (contact.email && contact.email.toLowerCase().indexOf(searchTerm) !== -1)
        || (contact.cellphone && contact.cellphone.toLowerCase().indexOf(searchTerm) !== -1)
        || (contact.department_canal && contact.department_canal.toLowerCase().indexOf(searchTerm) !== -1)
        || (contact.contact_function.label && contact.contact_function.label.toLowerCase().indexOf(searchTerm) !== -1)
        || (contact.region && contact.region.toLowerCase().indexOf(searchTerm) !== -1);
    };

    return filterFunction;
  }

  private compare(a: number | string, b: number | string, isAsc: boolean): number {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
}
