import { endpoints } from './../../../environments/endpoints';
import { Observable, from } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { TranslateLoader } from '@ngx-translate/core';


@Injectable()
export class CustomServerTranslateLoader implements TranslateLoader {
    contentHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' });

    constructor(private http: HttpClient) { }

    getTranslation(lang: string): Observable<any> {
        const apiAddress = environment.api.host + endpoints.i18n + '/bo/' + lang + '.json';
        return new Observable<Promise<any>>(observer => {
            this.http.get(apiAddress, { headers: this.contentHeader }).subscribe(
                (res: any) => {
                    const fileContents = res.data.contents.trim(); // Trim the string to remove unwanted UTF-8 prefix character
                    try {
                        observer.next(JSON.parse(fileContents));
                    } catch (error) {
                        console.error('An error occured trying to parse i18n file');
                        observer.complete();
                    }
                    observer.complete();
                },
                (error) => {
                    //  failed to retrieve from api, switch to local

                    // this.http.get('/assets/i18n/' + lang + '.json').subscribe((res: Response) => {
                    //     observer.next(res.json());
                    //     observer.complete();
                    // });
                    observer.complete();
                }
            );
        });
    }
}
