import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BasicLabelledObject } from 'src/app/models/basic-labelled-object.model';
import { Contact } from 'src/app/models/contact.model';
import { BasicObjectService } from '../../../services/basic-object.service';

@Component({
  selector: 'app-contact-create-update-dialog',
  templateUrl: './contact-create-update-dialog.component.html',
  styleUrls: ['./contact-create-update-dialog.component.scss']
})
export class ContactCreateUpdateDialogComponent implements OnInit {

  isNewContact: boolean;
  contactFunctions: BasicLabelledObject[];

  contactForm: FormGroup;

  constructor(
    private basicObjectService: BasicObjectService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<ContactCreateUpdateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public contactData?: Contact
  ) { }

  ngOnInit(): void {
    this.isNewContact = this.contactData === undefined;

    this.getContactFunctions();
    this.initForm();
  }

  submitContact(contactForm: FormGroup): void {
    if (!contactForm.valid) {
      contactForm.markAllAsTouched();
      return;
    }

    this.dialogRef.close({
      data: contactForm.value
    });
  }

  private getContactFunctions(): void {
    this.basicObjectService.getAll('contact_functions').subscribe(
      (contactFunctions: BasicLabelledObject[]) => {
        this.contactFunctions = contactFunctions;
      }
    );
  }

  private initForm(): void {
    const formData = new Contact(this.contactData);

    this.contactForm = this.fb.group({
      id: formData.id,
      igg: [formData.igg],
      name: [formData.name, Validators.required],
      email: [formData.email],
      cellphone: [formData.cellphone],
      function_id: [formData.function_id, Validators.required],
      type: [formData.type],
      registration_number: [formData.registration_number, Validators.maxLength(4)],
      department_canal: [formData.department_canal],
      region: [formData.region]
    });
  }
}
