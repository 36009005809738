<div class="main-content">
    <h2 class="title-with-actions">{{'matrices.title' | translate}}
        <div class="actions ml-auto">
            <button mat-flat-button color="primary" class="mr-2 mb-2" (click)="gotoMatriceCard()">
        <mat-icon class="mr-1">add</mat-icon>{{'matrices.create_matrice' | translate}}
      </button>
            <button mat-flat-button color="primary" class="mb-2" (click)="exportMatrices()">
        <mat-icon class="mr-1">download</mat-icon>{{'matrices.export' | translate}}
      </button>
        </div>
    </h2>
    <mat-card>
        <div class="search-bar">
            <form class="search-form" (ngSubmit)="filterMatrices()" [formGroup]="searchForm" novalidate>
                <mat-form-field class="search-input mr-3">
                    <mat-label>{{'matrices.search'|translate}}</mat-label>
                    <input matInput formControlName="query" (keyup)="filterMatrices()">
                    <span matSuffix class="d-flex">
            <mat-icon color="primary" class="pointer mr-2" (click)="clearFilter()"
              *ngIf="searchForm.controls.query.value">
              clear</mat-icon>
            <mat-icon color="primary" class="pointer" (click)="filterMatrices()">search</mat-icon>
          </span>
                </mat-form-field>
            </form>
        </div>
    </mat-card>

    <h3 class="mt-5">{{'matrices.matrice_list.title' | translate}}</h3>
    <mat-card>
        <div>
            <table mat-table [dataSource]="matricesDataSource" class="mt-3 w-100" matSort matSortActive="type" matSortDirection="asc">
                <ng-container matColumnDef="type">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'entities.incident_types.type' | translate}} </th>
                    <td mat-cell *matCellDef="let row"> {{row.incident_type.type}} </td>
                </ng-container>
                <ng-container matColumnDef="title">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'entities.incident_types.description' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let row"> {{row.incident_type.description}} </td>
                </ng-container>
                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef>
                        <div class="mat-header-content">
                            {{'general.table_headers.actions' | translate}}
                        </div>
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <button mat-icon-button (click)="gotoMatriceCard(row.id)">
              <mat-icon color="primary">edit</mat-icon>
            </button>
                        <button mat-icon-button (click)="deleteMatrice(row)">
              <mat-icon color="primary">delete</mat-icon>
            </button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="6">
                        <ng-container *ngIf="searchForm.controls.query.value; else noDataTemplate">
                            {{'general.table_no_data_filter' | translate: {filterValue: searchForm.controls.query.value } }}
                        </ng-container>
                        <ng-template #noDataTemplate>
                            {{'general.table_no_data' | translate}}
                        </ng-template>
                    </td>
                </tr>
            </table>

            <mat-paginator [length]="matricesDataSource?.data?.length" [pageSizeOptions]="[5,10,20]">
            </mat-paginator>
        </div>
    </mat-card>
</div>