<div class="main-content">
  <h2 class="title-with-actions">{{'users.title' | translate}}
    <div class="actions ml-auto">
      <button mat-flat-button color="primary" class="mr-2 mb-2" (click)="openCreateUpdateUserDialog()">
        <mat-icon class="mr-1">add</mat-icon>{{'users.create_user' | translate}}
      </button>
      <button mat-flat-button color="primary" class="mb-2" (click)="exportUsers()">
        <mat-icon class="mr-1">download</mat-icon>{{'users.export' | translate}}
      </button>
    </div>
  </h2>
  <mat-card>
    <div class="search-bar">
      <form class="search-form" (ngSubmit)="filterUsers()" [formGroup]="searchForm" novalidate>
        <mat-form-field class="search-input mr-3">
          <mat-label>{{'users.search'|translate}}</mat-label>
          <input matInput formControlName="query" (keyup)="filterUsers()">
          <span matSuffix class="d-flex">
            <mat-icon color="primary" class="pointer mr-2" (click)="clearFilter()"
              *ngIf="searchForm.controls.query.value">
              clear</mat-icon>
            <mat-icon color="primary" class="pointer" (click)="filterUsers()">search</mat-icon>
          </span>
        </mat-form-field>
      </form>
    </div>
  </mat-card>

  <h3 class="mt-5">{{'users.user_list.title' | translate}}</h3>
  <mat-card>
    <div>
      <table mat-table [dataSource]="usersDataSource" class="mt-3 w-100" matSort matSortActive="igg"
        matSortDirection="asc">
        <ng-container matColumnDef="igg">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'entities.users.igg' | translate}} </th>
          <td mat-cell *matCellDef="let row"> {{row.igg || ''}} </td>
        </ng-container>
        <ng-container matColumnDef="last_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'entities.users.last_name' | translate}}
          </th>
          <td mat-cell *matCellDef="let row"> {{row.last_name}} </td>
        </ng-container>
        <ng-container matColumnDef="first_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'entities.users.first_name' |
            translate}} </th>
          <td mat-cell *matCellDef="let row"> {{row.first_name}} </td>
        </ng-container>
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'entities.users.email' | translate}} </th>
          <td mat-cell *matCellDef="let row"> {{row.email}} </td>
        </ng-container>
        <ng-container matColumnDef="role">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'entities.users.role' | translate}} </th>
          <td mat-cell *matCellDef="let row"> {{row.role.code}} </td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>
            <div class="mat-header-content">
              {{'general.table_headers.actions' | translate}}
            </div>
          </th>
          <td mat-cell *matCellDef="let row">
            <button mat-icon-button (click)="openCreateUpdateUserDialog(row)">
              <mat-icon color="primary">edit</mat-icon>
            </button>
            <button mat-icon-button (click)="deleteUser(row)">
              <mat-icon color="primary">delete</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="6">
            <ng-container *ngIf="searchForm.controls.query.value; else noDataTemplate">
              {{'general.table_no_data_filter' | translate: {filterValue: searchForm.controls.query.value } }}
            </ng-container>
            <ng-template #noDataTemplate>
              {{'general.table_no_data' | translate}}
            </ng-template>
          </td>
        </tr>
      </table>

      <mat-paginator [length]="usersDataSource?.data?.length" [pageSizeOptions]="[5,10,20]">
      </mat-paginator>
    </div>
  </mat-card>
</div>
