import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { LoaderService } from '@ff/loader';
import { FileSaverService } from 'ngx-filesaver';

@Injectable({
  providedIn: 'root'
})
export class UtilServiceService {

  constructor(
    private datePipe: DatePipe,
    private fileSaver: FileSaverService
  ) { }

  exportXlsx(blob: Blob, fileName: string): void {
    const todayDate = this.datePipe.transform(new Date(), 'yyyyMMdd');
    this.fileSaver.save(blob, `${fileName} export - ${todayDate}.xlsx`);
  }

}

export function loaderShow(_loaderService: LoaderService) {
  if (!_loaderService.isVisible()) {
    _loaderService.show();
  }
}

export function loaderHide(_loaderService: LoaderService) {
  if (_loaderService.isVisible()) {
    _loaderService.hide();
  }
}
