<ng-container *ngIf="configurationFormGroup">
  <div class="main-content">
    <h2 class="title-with-actions">{{'configuration.title' | translate}}
    </h2>

    <h3 class="mt-5">{{'configuration.phones.title' | translate}}</h3>
    <mat-card>
      <form [formGroup]="configurationFormGroup" class="configuration-form">
        <!-- WHAT THE F*CK ANGULAR? First form field won't display value, I don't know why so I put this hidden field as a workaround... -->
        <mat-form-field class="d-none">
          <mat-label>{{'configuration.phones.car' | translate}}</mat-label>
          <input matInput formControlName="car">
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{'configuration.phones.car' | translate}}</mat-label>
          <input matInput formControlName="car">
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{'configuration.phones.com' | translate}}</mat-label>
          <input matInput formControlName="com">
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{'configuration.phones.dcm' | translate}}</mat-label>
          <input matInput formControlName="dcm">
        </mat-form-field>
      </form>
    </mat-card>

    <div class="page-actions mt-3">
      <button mat-flat-button color="primary" (click)="savePhones()">
        <mat-icon class="mr-2 mb-1">save</mat-icon>
        {{'general.buttons.save' | translate}}
      </button>
    </div>

    <h3 class="mt-5">Synchronisation</h3>
    <mat-card>
      <form [formGroup]="synchroFormGroup" class="configuration-form">
        <mat-form-field>
          <mat-label>Nombre de jours</mat-label>
          <input type="number" matInput formControlName="nbj_sync">
        </mat-form-field>
      </form>
    </mat-card>
    <div class="page-actions mt-3">
      <button mat-flat-button color="primary" (click)="handleSubmitNbrJrs()">
        <mat-icon class="mr-2 mb-1">save</mat-icon>
        {{'general.buttons.save' | translate}}
      </button>
    </div>

    <h3 class="mt-5">{{'configuration.translations.title' | translate}}</h3>
    <mat-card>
      <mat-list class="translations">
        <h3 mat-subheader>Front-office</h3>
        <div class="alert alert-danger upload_error" *ngIf="hasFoUploadError">{{foErrorMessage}}</div>
        <mat-list-item *ngFor="let foFileName of foFileNames" class="mat-elevation-z1">
          <mat-icon mat-list-icon>list_alt</mat-icon>
          <div mat-line class="d-flex align-items-center">
            <h3>{{ foFileName }}</h3>
            <div class="ml-auto">
              <button mat-flat-button class="mr-4" (click)="getFile(foFileName, 'fo')">Télécharger</button>
              <input #fileInput hidden (change)="fileChange($event, foFileName, 'fo')" type="file"
                accept="application/json">
              <button mat-flat-button color="primary" (click)="fileInput.click()">Mettre à jour...</button>
            </div>
          </div>
        </mat-list-item>
        <mat-divider></mat-divider>
        <h3 mat-subheader>Back-office</h3>
        <div class="alert alert-danger upload_error" *ngIf="hasBoUploadError">{{boErrorMessage}}</div>
        <mat-list-item *ngFor="let boFileName of boFileNames" class="mat-elevation-z1">
          <mat-icon mat-list-icon>list_alt</mat-icon>
          <div mat-line class="d-flex align-items-center">
            <h3>{{ boFileName }}</h3>
            <div class="ml-auto">
              <button mat-flat-button class="mr-4" (click)="getFile(boFileName, 'bo')">Télécharger</button>
              <input #fileInput hidden (change)="fileChange($event, boFileName, 'bo')" type="file"
                accept="application/json">
              <button mat-flat-button color="primary" (click)="fileInput.click()">Mettre à jour...</button>
            </div>
          </div>
        </mat-list-item>
      </mat-list>
    </mat-card>


    <h3 class="mt-5">{{'configuration.documents.title' | translate}}</h3>
    <mat-card>
      <div class="mb-4">
        <div class="d-flex justify-content-between">
          <div class="d-flex align-items-center mb-2">
            <mat-icon mat-list-icon class="mr-2">description</mat-icon>
            <div>RCU</div>
          </div>
          <div>
            <input type="file" #rcuInput hidden (change)="docChange($event, 'rcu')" accept="application/pdf" />
            <button mat-flat-button color="primary" (click)="rcuInput.click()">
              <mat-icon class="mr-2">save</mat-icon>
              {{'configuration.documents.upload' | translate}}
            </button>
          </div>
        </div>
      </div>

      <div class="mb-4">
        <div class="d-flex justify-content-between">
          <div class="d-flex align-items-center mb-2">
            <mat-icon mat-list-icon class="mr-2">description</mat-icon>
            <div>Autre document</div>
          </div>
          <input type="file" #docInput hidden (change)="docChange($event, 'document')" accept="application/pdf" />
          <button mat-flat-button color="primary" (click)="docInput.click()">
            <mat-icon class="mr-2">save</mat-icon>
            {{'configuration.documents.upload' | translate}}
          </button>
        </div>
      </div>

      <div class="mb-4">
        <div class="d-flex justify-content-between">
          <div class="d-flex align-items-center mb-2">
            <mat-icon mat-list-icon class="mr-2">description</mat-icon>
            <div>Planning d'astreinte</div>
          </div>
          <input type="file" #planningInput hidden (change)="docChange($event, 'planning')" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
          <button mat-flat-button color="primary" (click)="planningInput.click()">
            <mat-icon class="mr-2">save</mat-icon>
            {{'configuration.documents.upload' | translate}}
          </button>
        </div>
      </div>

      <div class="mb-4">
        <div class="d-flex justify-content-between">
          <div class="d-flex align-items-center mb-2">
            <mat-icon mat-list-icon class="mr-2">description</mat-icon>
            <div>Conditions générales d'utilisation</div>
          </div>
          <input type="file" #cguInput hidden (change)="docChange($event, 'cgu')" accept="application/pdf" />
          <button mat-flat-button color="primary" (click)="cguInput.click()">
            <mat-icon class="mr-2">save</mat-icon>
            {{'configuration.documents.upload' | translate}}
          </button>
        </div>
      </div>

      <div class="mb-4">
        <div class="d-flex justify-content-between">
          <div class="d-flex align-items-center mb-2">
            <mat-icon mat-list-icon class="mr-2">description</mat-icon>
            <div>Charte sur les données personnelles</div>
          </div>
          <input type="file" #privacyPolicyInput hidden (change)="docChange($event, 'privacy_policy')" accept="application/pdf" />
          <button mat-flat-button color="primary" (click)="privacyPolicyInput.click()">
            <mat-icon class="mr-2">save</mat-icon>
            {{'configuration.documents.upload' | translate}}
          </button>
        </div>
      </div>
    </mat-card>
  </div>
</ng-container>

<ng-template #loader>
  <mat-progress-spinner color="primary"></mat-progress-spinner>
</ng-template>
