import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { equals, password } from '@ff/core';
import { LoaderService } from '@ff/loader';
import { UserService } from '@ff/user';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { validateIgg } from 'src/app/modules/shared/validators/igg.validator';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit, OnDestroy {

  private userInfoForm: FormGroup;
  private userPasswordForm: FormGroup;
  private user: any;
  private subscriptions: Subscription[] = [];

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private loader: LoaderService,
    private snackBar: MatSnackBar,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.user = this.userService.getUser().getValue();
    this.userInfoForm = this.fb.group({
      id: [this.user.id, [Validators.required]],
      first_name: [this.user.first_name, [Validators.required, Validators.maxLength(255)]],
      last_name: [this.user.last_name, [Validators.required, Validators.maxLength(255)]],
      igg: [this.user.igg, [Validators.required, validateIgg()]],
      email: [{
        value: this.user.email,
        disabled: true
      }, [Validators.required, Validators.maxLength(255), Validators.email]]
    });

    this.userPasswordForm = this.fb.group({
      oldPassword: ['', [Validators.required]],
      newPassword: ['', [Validators.required, Validators.minLength(8), password()]],
      newPassword_confirmation: ['', [Validators.required, equals('newPassword')]]
    });

    this.subscriptions.push(
      this.userPasswordForm.controls.newPassword.valueChanges.subscribe(
        () => {
          this.userPasswordForm.controls.newPassword_confirmation.setValue('');
        })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(
      (subscription: Subscription) => subscription.unsubscribe()
    );
  }

  public getUserInfoForm(): FormGroup {
    return this.userInfoForm;
  }

  public getUserPasswordForm(): FormGroup {
    return this.userPasswordForm;
  }

  public saveUserInfo(): void {
    this.loader.show();
    this.userService.save(this.userInfoForm)
      .then(
        (response: any) => {
          this.userInfoForm.patchValue({ ...response.data });
          this.userService.getUser().next(response.data);
          this.snackBar.open(this.translate.instant('general.messages.data_saved'));
          this.loader.hide();
        })
      .catch((error: any) => {
        this.snackBar.open(this.translate.instant('general.messages.errors.generic'));
        this.loader.hide();
      })
      .finally(() => {
        this.loader.hide();
      });
  }
}
