<nav>
    <div class="title">
        <img src="assets/logo.svg" title="Total" alt="Total" class="logo pointer" [routerLink]="'/app/incidents'" />
    </div>

    <ul>
        <ng-container *ngFor="let route of getRoutes()">
            <ng-container *ngIf="route.data?.title">
                <li class="pointer" routerLinkActive="active" [routerLink]="'app/'+ route.path" *ngIf="route.path">
                    <mat-icon *ngIf="route.data?.icon" class="mr-3">{{route.data.icon}}</mat-icon>
                    <span class="item-text">{{'navigation.' + route.data.title | translate}}</span>
                </li>
            </ng-container>
        </ng-container>
    </ul>
</nav>
