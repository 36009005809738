import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { LoaderService } from '@ff/loader';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';
import { Matrice } from 'src/app/models/matrice.model';
import { absoluteRoutesNames } from 'src/app/modules/shared/absolute.routes';
import { editorConfig } from 'src/app/modules/shared/configs/angular-editor.config';
import { BasicLabelledObject } from './../../../../../models/basic-labelled-object.model';
import { MatriceContactFunction } from './../../../../../models/matrice-contact-function.model';
import { MatriceSeverityLevel } from './../../../../../models/matrice-severity-level.model';
import { SeverityLevel } from './../../../../../models/severity-level.model';
import { BasicObjectService } from './../../../services/basic-object.service';
import { MatriceService } from './../../../services/matrice.service';

@Component({
  selector: 'app-matrice-card',
  templateUrl: './matrice-card.component.html',
  styleUrls: ['./matrice-card.component.scss']
})
export class MatriceCardComponent implements OnInit {

  contactFunctions: BasicLabelledObject[];
  severityLevels: SeverityLevel[];
  matriceTypes = ['COM', 'DCM'];
  contactTypes = ['tel', 'sms', 'email'];

  matriceId: number;
  matriceForm: FormGroup;

  editorConfig: AngularEditorConfig = editorConfig;

  constructor(
    private route: ActivatedRoute,
    private loader: LoaderService,
    private basicObjectService: BasicObjectService,
    private matriceService: MatriceService,
    private fb: FormBuilder,
    private translate: TranslateService,
    private snackBar: MatSnackBar,
    private router: Router
  ) { }

  get matriceSeverityLevelsArray(): FormArray {
    return this.matriceForm.get('matrice_severity_levels') as FormArray;
  }

  getMatriceContactFunctionsArray(matriceSeveritylevelIndex: number): FormArray {
    const msl = this.matriceForm.get('matrice_severity_levels') as FormArray;
    return msl.controls[matriceSeveritylevelIndex].get('matrice_contact_functions') as FormArray;
  }

  ngOnInit(): void {
    this.initLists();
  }

  initData(): void {
    this.route.params.subscribe(params => {
      this.matriceId = (params?.id !== absoluteRoutesNames.ADD) ? +params.id : null;
      if (!this.matriceId) {
        this.initForm();
        return;
      }

      this.loader.show();
      this.matriceService.get(this.matriceId).subscribe(
        (matrice: Matrice) => {
          this.initForm(matrice);
        },
        (error) => { this.loader.hide(); },
        () => { this.loader.hide(); }
      );
    });
  }

  initLists(): void {
    const requests = [
      this.basicObjectService.getAll('contact_functions'),
      this.matriceService.getSeverityLevels(),
    ];
    forkJoin(requests).subscribe(([contactFunctions, severityLevels]: [BasicLabelledObject[], SeverityLevel[]]) => {
      this.contactFunctions = contactFunctions;
      this.severityLevels = severityLevels;

      this.initData();
    });
  }

  initForm(matrice?: Matrice): void {
    const matriceData = matrice ? matrice : new Matrice();

    this.matriceForm = this.fb.group({
      id: matriceData.id,
      incident_type: this.fb.group({
        id: matriceData.incident_type?.id,
        type: [matriceData.incident_type?.type, Validators.required],
        description: [matriceData.incident_type?.description, Validators.required],
        reflex_sheet: [matriceData.incident_type?.reflex_sheet, Validators.required],
        is_active: [matriceData.incident_type?.is_active],

      }),
      matrice_severity_levels: this.fb.array([])
    });

    if (this.matriceId) {
      this.matriceForm.get('incident_type.type').disable();
    }
    this.initMatriceSeverityLevelsFormArray(matriceData.matrice_severity_levels);
  }

  initMatriceSeverityLevelsFormArray(matriceSeverityLevels: MatriceSeverityLevel[]): void {
    this.severityLevels.forEach(severityLevel => {
      const matriceSeverityLevel = matriceSeverityLevels?.find(msl => msl.level === severityLevel.level);
      this.addMatriceSeverityLevel(severityLevel, matriceSeverityLevel);
    });
  }

  addMatriceSeverityLevel(severityLevel: SeverityLevel, matriceSeverityLevel: MatriceSeverityLevel): void {
    this.matriceSeverityLevelsArray.push(
      this.fb.group({
        id: matriceSeverityLevel?.id,
        level: severityLevel.level,
        code: severityLevel.code,
        hex_color: severityLevel.hex_color,
        description: [matriceSeverityLevel?.description, Validators.required],
        matrice_contact_functions: this.getMatriceSeverityLevelContactsFormArray(matriceSeverityLevel)
      })
    );
  }

  getMatriceSeverityLevelContactsFormArray(matriceSeverityLevel: MatriceSeverityLevel): FormArray {
    const matriceContacts = this.fb.array([]);
    if (!matriceSeverityLevel) { return matriceContacts; }

    matriceSeverityLevel.matrice_contact_functions.forEach((matriceContact: MatriceContactFunction) => {
      matriceContacts.push(this.fb.group({
        contact_function_id: matriceContact.contact_function.id,
        contact_type: matriceContact.contact_type
      }));
    });
    return matriceContacts;
  }

  addMatriceContact(matriceSeveritylevelIndex: number): void {
    const contacts = this.getMatriceContactFunctionsArray(matriceSeveritylevelIndex);
    contacts.push(this.fb.group({
      contact_function_id: [null, Validators.required],
      contact_type: ['tel', Validators.required]
    }));
  }

  removeMatriceContact(matriceSeveritylevelIndex: number, matriceContactIndex: number): void {
    const contacts = this.getMatriceContactFunctionsArray(matriceSeveritylevelIndex);
    contacts.removeAt(matriceContactIndex);
  }

  getMatriceTitleLength(): void {
    const value = this.matriceForm.get('incident_type.description').value;
    return (value ? value.length : 0);
  }

  getLevelDescriptionLength(rowIndex: number): void {
    const value = this.matriceSeverityLevelsArray.controls[rowIndex]?.value;
    return ((value && value.description) ? value.description.length : 0);
  }

  gotoMatrices(): void {
    this.router.navigate([absoluteRoutesNames.MATRICES]);
  }

  submitMatrice(matriceForm: FormGroup): void {
    if (!matriceForm.valid) {
      matriceForm.markAllAsTouched();

      this.snackBar.open(this.translate.instant('general.messages.errors.required_fields'));
      return;
    }

    const matriceData = matriceForm.value;
    const httpRequest = this.matriceId ?
      this.matriceService.update(matriceData) : this.matriceService.create(matriceData);

    httpRequest.subscribe(() => {
      this.snackBar.open(this.translate.instant('general.messages.data_saved'));
      this.gotoMatrices();
    });
  }

  isOptionAlreadyChosenForLevel(msl: FormControl, contactId: number): boolean {
    return msl.value.matrice_contact_functions.findIndex(mcf => mcf.contact_function_id === contactId) > -1;
  }
}
