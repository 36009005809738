import { BasicCodedObject } from './basic-coded-object.model';

// tslint:disable:variable-name
export class Provider {

  id: number;
  provider_type_id: number;
  company: string;
  role: string;
  role_visitor_dcm: boolean;
  logo: string;
  phone_1: string;
  phone_2: string;
  phone_3: string;
  email: string;
  email_2: string;
  opening_hours: string;
  geographic_scope: string;
  created_at: string;
  updated_at: string;

  pivot?: { station_id: number, provider_id: number };

  provider_type: BasicCodedObject;

  constructor(data = {}) {
    Object.assign(this, data);
    this.provider_type = new BasicCodedObject(this.provider_type);
  }
}
