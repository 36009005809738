export const endpoints = {
  coded_objects: {
    providerTypes: 'provider-types',
    station_statuses: 'station-statuses'
  },
  labelled_objects: {
    brands: 'brands',
    contact_functions: 'contact-functions',
    sales_channels: 'sales-channels',
    segmentations: 'segmentations',
    slas: 'slas',
    scas: 'scas',
    specifics: 'specifics'
  },
  check_code: '/check-code',
  configuration: '/configuration',
  contacts: '/contacts',
  documents: '/documents',
  export: '/export',
  i18n: '/static/i18n',
  incidents: '/incidents',
  linked_stations: '/linked-stations',
  matrices: '/matrices',
  providers: '/providers',
  roles: '/roles',
  search: '/search',
  severity_levels: '/severity-levels',
  stations: '/stations',
  users: '/users',
};
