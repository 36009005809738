import { Component } from '@angular/core';
import { ATInternetService } from '@ff/at-internet';
import { UserService } from '@ff/user';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  public constructor(
    private translate: TranslateService,
    public userService: UserService,
    public atInternet: ATInternetService
  ) {
    this.initLangs();
  }

  private initLangs(): void {
    const currentLang = this.translate.currentLang || 'fr';
    this.translate.use(currentLang);
  }
}
