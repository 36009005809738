import { User } from './../../../../../models/user.model';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-delete-user-dialog',
  templateUrl: './confirm-delete-user-dialog.component.html',
  styleUrls: ['./confirm-delete-user-dialog.component.scss']
})
export class ConfirmDeleteUserDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ConfirmDeleteUserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public userAcount?: User
  ) { }

  ngOnInit(): void {
  }

  confirmDeletion(): void {
    this.dialogRef.close({
      confirm: true
    });
  }
}
