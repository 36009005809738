import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { LoaderService } from '@ff/loader';
import { TranslateService } from '@ngx-translate/core';
import { Provider } from 'src/app/models/provider.model';
import { UtilServiceService } from 'src/app/modules/shared/services/util-service.service';
import { ProviderCreateUpdateDialogComponent } from '../providers/provider-create-update-dialog/provider-create-update-dialog.component';
import { ProviderService } from './../../services/provider.service';
import { ConfirmDeleteProviderDialogComponent } from './confirm-delete-provider-dialog/confirm-delete-provider-dialog.component';
import { LinkedStationsDialogComponent } from './linked-stations-dialog/linked-stations-dialog.component';

@Component({
  selector: 'app-providers',
  templateUrl: './providers.component.html',
  styleUrls: ['./providers.component.scss']
})
export class ProvidersComponent implements OnInit, AfterViewInit {

  providersDataSource: MatTableDataSource<Provider> = new MatTableDataSource([]);
  sortedProviders: Provider[];
  displayedColumns = ['company', 'role', 'provider_type', 'phone_1', 'phone_2', 'phone_3', 'email', 'email_2', 'actions'];

  searchForm: FormGroup;
  providerForm: FormGroup;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private fb: FormBuilder,
    private providerService: ProviderService,
    private loader: LoaderService,
    private dialog: MatDialog,
    private translate: TranslateService,
    private utilService: UtilServiceService
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.initData();
  }

  ngAfterViewInit(): void {
    this.providersDataSource.paginator = this.paginator;
    this.providersDataSource.sort = this.sort;
    this.providersDataSource.sortData = this.sortData.bind(this);
  }

  exportProviders(): void {
    this.loader.show();
    this.providerService.export().subscribe(
      (res: Blob) => {
        this.loader.hide();
        this.utilService.exportXlsx(res, 'prestataires');
      },
      (error) => { this.loader.hide(); },
      () => { this.loader.hide(); }
    );
  }

  clearFilter(): void {
    this.searchForm.reset();
    this.filterProviders();
  }

  filterProviders(): void {
    const filterValue = this.searchForm.value.query || '';
    this.providersDataSource.filter = filterValue.trim().toLowerCase();

    if (this.providersDataSource.paginator) {
      this.providersDataSource.paginator.firstPage();
    }
  }

  openCreateUpdateProviderDialog(providerData?: Provider): void {
    const dialogRef = this.dialog.open(ProviderCreateUpdateDialogComponent, {
      data: providerData,
      width: '66vw'
    });

    dialogRef.afterClosed().subscribe(dialogRes => {
      if (!dialogRes?.data) { return; }

      this.loader.show();
      const request = providerData ?
        this.providerService.update(dialogRes.data) : this.providerService.create(dialogRes.data);
      request.subscribe(
        (res: any) => {
          this.getProviders();
        },
        (error) => { this.loader.hide(); },
        () => { this.loader.hide(); }
      );
    });
  }

  openLinkedStationsDialog(providerId: number): void {
    const dialogRef = this.dialog.open(LinkedStationsDialogComponent, {
      disableClose: true,
      data: providerId,
      width: '66vw'
    });
  }

  deleteProvider(provider: Provider): void {
    const dialogRef = this.dialog.open(ConfirmDeleteProviderDialogComponent, {
      data: provider,
      width: '66vw'
    });

    dialogRef.afterClosed().subscribe(dialogRes => {
      if (dialogRes !== 'confirm') { return; }

      this.providerService.delete(provider.id).subscribe(() => {
        this.getProviders();
      });
    });
  }

  sortData(data: Provider[], sort: Sort): Provider[] {
    if (!sort.active || sort.direction === '') {
      return data;
    }

    return data.sort((a: Provider, b: Provider) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'company': return this.compare(a.company, b.company, isAsc);
        case 'role': return this.compare(a.role, b.role, isAsc);
        case 'provider_type': return this.compare(
          this.translate.instant(a.provider_type.code), this.translate.instant(b.provider_type.code),
          isAsc
        );
        case 'phone_1': return this.compare(a.phone_1, b.phone_1, isAsc);
        case 'phone_2': return this.compare(a.phone_2, b.phone_2, isAsc);
        case 'phone_3': return this.compare(a.phone_3, b.phone_3, isAsc);
        case 'email': return this.compare(a.email, b.email, isAsc);
        case 'email_2': return this.compare(a.email_2, b.email_2, isAsc);
        default: return 0;
      }
    });
  }

  private initForm(): void {
    this.searchForm = this.fb.group({
      query: [null]
    });
  }

  private initData(): void {
    this.getProviders();
  }

  private getProviders(): void {
    if (!this.loader.isVisible()) { this.loader.show(); }

    this.providerService.getAll().subscribe(
      (providers: Provider[]) => {
        this.providersDataSource.data = providers;
        this.providersDataSource.filterPredicate = this.buildTableFilter().bind(this);
      },
      (error) => { this.loader.hide(); },
      () => { this.loader.hide(); }
    );
  }

  private buildTableFilter(): (provider: Provider, filter: string) => boolean {
    const filterFunction = (provider: Provider, filter: string): boolean => {
      if (!filter || filter.trim().toLocaleLowerCase().length < 1) {
        return true;
      }

      const searchTerm = filter.trim().toLowerCase();

      const providerTypeLabel = this.translate.instant(`entities.provider_types.${provider.provider_type.code}`);


      return (provider.company && provider.company.toLowerCase().indexOf(searchTerm) !== -1)
        || (provider.role && provider.role.toLowerCase().indexOf(searchTerm) !== -1)
        || (provider.phone_1 && provider.phone_1.toLowerCase().indexOf(searchTerm) !== -1)
        || (provider.phone_2 && provider.phone_2.toLowerCase().indexOf(searchTerm) !== -1)
        || (provider.phone_3 && provider.phone_3.toLowerCase().indexOf(searchTerm) !== -1)
        || (provider.email && provider.email.toLowerCase().indexOf(searchTerm) !== -1)
        || (provider.email_2 && provider.email_2.toLowerCase().indexOf(searchTerm) !== -1)
        || (providerTypeLabel.toLowerCase().indexOf(searchTerm) !== -1);
    };

    return filterFunction;
  }

  private compare(a: number | string, b: number | string, isAsc: boolean): number {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
}
