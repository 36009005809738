import { ConfirmDeleteUserDialogComponent } from './confirm-delete-user-dialog/confirm-delete-user-dialog.component';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { LoaderService } from '@ff/loader';
import { User } from './../../../../models/user.model';
import { UserService } from './../../services/user.service';
import { UserCreateUpdateDialogComponent } from './user-create-update-dialog/user-create-update-dialog.component';
import { UtilServiceService } from 'src/app/modules/shared/services/util-service.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit, AfterViewInit {

  usersDataSource: MatTableDataSource<User> = new MatTableDataSource([]);
  sortedUsers: User[];
  displayedColumns = ['igg', 'last_name', 'first_name', 'email', 'role', 'actions'];

  searchForm: FormGroup;
  userForm: FormGroup;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private loader: LoaderService,
    private dialog: MatDialog,
    private utilService: UtilServiceService
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.initData();
  }

  ngAfterViewInit(): void {
    this.usersDataSource.paginator = this.paginator;
    this.usersDataSource.sort = this.sort;
    this.usersDataSource.sortData = this.sortData.bind(this);
  }

  exportUsers(): void {
    this.loader.show();
    this.userService.export().subscribe(
      (res: Blob) => {
        this.loader.hide();
        this.utilService.exportXlsx(res, 'utilisateurs');
      },
      (error) => { this.loader.hide(); },
      () => { this.loader.hide(); }
    );
  }

  clearFilter(): void {
    this.searchForm.reset();
    this.filterUsers();
  }

  filterUsers(): void {
    const filterValue = this.searchForm.value.query || '';
    this.usersDataSource.filter = filterValue.trim().toLowerCase();

    if (this.usersDataSource.paginator) {
      this.usersDataSource.paginator.firstPage();
    }
  }

  openCreateUpdateUserDialog(userData?: User): void {
    const dialogRef = this.dialog.open(UserCreateUpdateDialogComponent, {
      data: userData,
      width: '66vw'
    });

    dialogRef.afterClosed().subscribe(dialogRes => {
      if (!dialogRes?.data) { return; }

      this.loader.show();
      const request = userData ? this.userService.update(dialogRes.data) : this.userService.create(dialogRes.data);
      request.subscribe(
        (res: any) => {
          this.getUsers();
        },
        (error) => { this.loader.hide(); },
        () => { this.loader.hide(); }
      );
    });
  }

  deleteUser(user: User): void {
    const dialogRef = this.dialog.open(ConfirmDeleteUserDialogComponent, {
      data: user,
      width: '45rem'
    });

    dialogRef.afterClosed().subscribe(data => {
      if (!data) { return; }

      this.userService.delete(user.id).subscribe(() => {
        this.getUsers();
      });
    });
  }

  isVisitorUser(user: User): boolean {
    return user.role.code.toLowerCase() === 'visitor';
  }

  sortData(data: User[], sort: Sort): User[] {
    if (!sort.active || sort.direction === '') {
      return data;
    }

    return data.sort((a: User, b: User) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'igg': return this.compare(a.igg, b.igg, isAsc);
        case 'last_name': return this.compare(a.last_name, b.last_name, isAsc);
        case 'first_name': return this.compare(a.first_name, b.first_name, isAsc);
        case 'email': return this.compare(a.email, b.email, isAsc);
        case 'role': return this.compare(a.role.code, b.role.code, isAsc);
        default: return 0;
      }
    });
  }

  private initForm(): void {
    this.searchForm = this.fb.group({
      query: [null]
    });
  }

  private initData(): void {
    this.getUsers();
  }

  private getUsers(): void {
    if (!this.loader.isVisible()) { this.loader.show(); }

    this.userService.getAll().subscribe(
      (users: User[]) => {
        this.usersDataSource.data = users;
        this.usersDataSource.filterPredicate = this.buildTableFilter().bind(this);
      },
      (error) => { this.loader.hide(); },
      () => { this.loader.hide(); }
    );
  }

  private buildTableFilter(): (user: User, filter: string) => boolean {
    const filterFunction = (user: User, filter: string): boolean => {
      if (!filter || filter.trim().toLocaleLowerCase().length < 1) {
        return true;
      }

      const searchTerm = filter.trim().toLowerCase();

      return user.igg?.toLowerCase().indexOf(searchTerm) !== -1
        || (user.last_name && user.last_name.toLowerCase().indexOf(searchTerm) !== -1)
        || (user.first_name && user.first_name.toLowerCase().indexOf(searchTerm) !== -1)
        || user.email.toLowerCase().indexOf(searchTerm) !== -1
        || user.role.code.toLowerCase().indexOf(searchTerm) !== -1;
    };

    return filterFunction;
  }

  private compare(a: number | string, b: number | string, isAsc: boolean): number {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
}
