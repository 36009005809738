<div class="main-content">
  <form [formGroup]="incidentForm" (ngSubmit)="filterIncidents()" novalidate>
    <h2 class="title-with-actions">{{'incidents.search.title' | translate}}
      <div class="actions ml-auto">
        <button type="button" mat-flat-button color="primary" (click)="exportIncidents()">
          <mat-icon>download</mat-icon>{{'incidents.export' | translate}}
        </button>
      </div>
    </h2>
    <mat-card>
      <div class="search-bar">
        <mat-form-field class="search-input mr-3 w-auto">
          <mat-label>{{'incidents.search.placeholder'|translate}}</mat-label>
          <input matInput formControlName="query">
        </mat-form-field>
        <div class="mr-2">
          <mat-form-field class="mat-date-xs" class="disabled-input-datepicker" (click)="pickerStart.open()">
            <mat-label>{{'incidents.search.from'|translate}}</mat-label>
            <input matInput [matDatepicker]="pickerStart" formControlName="date_start" readonly>
            <div matSuffix class="d-flex align-items-center">
              <button mat-icon-button type="button" (click)="clearSearchDate('date_start')"
                *ngIf="incidentForm.get('date_start').value">
                <mat-icon color="primary">close</mat-icon>
              </button>
              <mat-datepicker-toggle [for]="pickerStart"></mat-datepicker-toggle>
            </div>
            <mat-datepicker #pickerStart></mat-datepicker>
          </mat-form-field>
          <mat-form-field class="mat-date-xs" class="disabled-input-datepicker" (click)="pickerEnd.open()">
            <mat-label>{{'incidents.search.to'|translate}}</mat-label>
            <input matInput [matDatepicker]="pickerEnd" formControlName="date_end" readonly>
            <div matSuffix class="d-flex align-items-center">
              <button mat-icon-button type="button" (click)="clearSearchDate('date_end')"
                *ngIf="incidentForm.get('date_end').value">
                <mat-icon color="primary">close</mat-icon>
              </button>
              <mat-datepicker-toggle [for]="pickerEnd"></mat-datepicker-toggle>
            </div>
            <mat-datepicker #pickerEnd></mat-datepicker>
          </mat-form-field>
        </div>
        <button mat-mini-fab color="primary" (click)="filterIncidents()" class="search-button">
          <mat-icon>search</mat-icon>
        </button>
      </div>
    </mat-card>

    <h3 class="mt-5">{{'incidents.search.results_title' | translate}}</h3>
    <mat-card>
      <div #incidentsTableContainer class="table-container">
        <table mat-table #table [dataSource]="incidentsDataSource" class="w-100 table-striped" matSort
          matSortDisableClear matSortActive="declaration_date" matSortDirection="desc">
          <ng-container matColumnDef="declaration_date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'incidents.search.results.date'|translate}} </th>
            <td mat-cell *matCellDef="let row"> {{getFormattedDate(row.declaration_date, row.declaration_time)}} </td>
          </ng-container>
          <ng-container matColumnDef="creation_date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              {{'incidents.search.results.creation_date'|translate}} </th>
            <td mat-cell *matCellDef="let row"> {{getFormattedDateFromFullDate(row.created_at)}} </td>
          </ng-container>
          <ng-container matColumnDef="code_implant">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'incidents.search.results.code_implant'|translate}}
            </th>
            <td mat-cell *matCellDef="let row"> {{row.station.code_implant}} </td>
          </ng-container>
          <ng-container matColumnDef="site_name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'incidents.search.results.site_name'|translate}}
            </th>
            <td mat-cell *matCellDef="let row"> {{row.station.info.site_name}} </td>
          </ng-container>
          <ng-container matColumnDef="user">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'incidents.search.results.user'|translate}} </th>
            <td mat-cell *matCellDef="let row">
              {{(row.user?.last_name | uppercase) + " " + row.user?.first_name}}
            </td>
          </ng-container>
          <ng-container matColumnDef="role">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'incidents.search.results.oncall'|translate}} </th>
            <td mat-cell *matCellDef="let row">
              {{row.user?.role.code}}
            </td>
          </ng-container>
          <ng-container matColumnDef="severity">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'incidents.search.results.severity'|translate}}
            </th>
            <td mat-cell *matCellDef="let row">
              {{'entities.severity_levels.'+ row.severity_level.code | translate}}
            </td>
          </ng-container>
          <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'incidents.search.results.type'|translate}} </th>
            <td mat-cell *matCellDef="let row"> {{row.incident_type.description}} </td>
          </ng-container>
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef> {{'incidents.search.results.status'|translate}} </th>
            <td mat-cell *matCellDef="let row">
              {{'entities.incident_types.statuses.' + row.status?.code | translate}}
            </td>
          </ng-container>
          <ng-container matColumnDef="see">
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let row">
              <button mat-icon-button (click)="gotoIncidentCard(row.id)">
                <mat-icon color="primary">visibility</mat-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="incidentsDisplayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: incidentsDisplayedColumns;"></tr>
        </table>

        <mat-paginator [length]="incidentsPaginationItem.total" [pageSize]="25" (page)="paginationChanged($event)">
        </mat-paginator>
      </div>
    </mat-card>
  </form>
</div>
