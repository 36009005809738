import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Configuration } from 'src/app/models/configuration.model';
import { endpoints } from 'src/environments/endpoints';
import { environment } from 'src/environments/environment';
import { ErrorHandlerService } from '../../shared/services/error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {
  constructor(
    private http: HttpClient,
    private errorHandlerService: ErrorHandlerService,
  ) { }

  get(): Observable<Configuration> {
    return this.http.get(
      `${environment.api.host}${endpoints.configuration}`
    ).pipe(
      map(
        (res: any) => new Configuration(res.data.configuration)
      ),
      catchError(err => this.errorHandlerService.handleError(err))
    );
  }

  update(config: Configuration): Observable<any> {
    return this.http.patch(
      `${environment.api.host}${endpoints.configuration}`, config
    ).pipe(
      map(
        (res: any) => res
      ),
      catchError(err => this.errorHandlerService.handleError(err))
    );
  }

  getAllTranslationFileNames(): Observable<any> {
    return this.http.get(
      `${environment.api.host}${endpoints.i18n}`
    ).pipe(
      map(
        (res: any) => res
      ),
      catchError(err => this.errorHandlerService.handleError(err))
    );
  }

  getTranslationFile(filename: string): Observable<any> {
    return this.http.get(environment.api.host + endpoints.i18n + '/' + filename);
  }

  updateTranslationFile(fileData: { name: string; type: string; data: any; }, appId: string): Observable<any> {
    return this.http.post(environment.api.host + endpoints.i18n + `/${appId}`, { file: fileData });
  }

  updateDocumentFile(fileData: { name: string; type: string; data: any; }): Observable<any> {
    return this.http.post(environment.api.host + endpoints.documents, { file: fileData });
  }

  updateSyncDays(days: number): Observable<any> {
    return this.http.patch(environment.api.host + endpoints.configuration + '/sync-days', { sync_days: days });
  }
}
