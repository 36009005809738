import { Station } from './../../../models/station.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { endpoints } from 'src/environments/endpoints';
import { environment } from 'src/environments/environment';
import { Provider } from './../../../models/provider.model';
import { ErrorHandlerService } from './../../shared/services/error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class ProviderService {

  constructor(
    private http: HttpClient,
    private errorHandlerService: ErrorHandlerService
  ) { }

  getAll(): Observable<Provider[]> {
    return this.http.get(
      `${environment.api.host}${endpoints.providers}`
    ).pipe(
      map(
        (res: any) => res.data.providers.map(provider => new Provider(provider))
      ),
      catchError(err => this.errorHandlerService.handleError(err))
    );
  }

  getLinkedStations(providerId: number): Observable<Station[]> {
    return this.http.get(
      `${environment.api.host}${endpoints.providers}/${providerId}${endpoints.linked_stations}`
    ).pipe(
      map(
        (res: any) => res.data.linked_stations.map(ls => new Station(ls))
      ),
      catchError(err => this.errorHandlerService.handleError(err))
    );
  }

  create(providerFormValue: any): Observable<any> {
    return this.http.post(
      `${environment.api.host}${endpoints.providers}`, providerFormValue
    ).pipe(
      map(
        (res: any) => res
      ),
      catchError(err => this.errorHandlerService.handleError(err))
    );
  }

  update(providerData: Provider): Observable<any> {
    return this.http.patch(
      `${environment.api.host}${endpoints.providers}/${providerData.id}`, providerData
    ).pipe(
      map(
        (res: any) => res
      ),
      catchError(err => this.errorHandlerService.handleError(err))
    );
  }

  updateLinkedStations(providerId: number, linkedStationsIds: number[]): Observable<any> {
    return this.http.post(
      `${environment.api.host}${endpoints.providers}/${providerId}${endpoints.linked_stations}`,
      { stations_ids: linkedStationsIds }
    ).pipe(
      map(
        (res: any) => res.data.linked_stations.map(ls => new Station(ls))
      ),
      catchError(err => this.errorHandlerService.handleError(err))
    );
  }

  updateLinkedStationsByDepartments(providerId: number, departments: string[], isForRemove: boolean): Observable<any> {
    return this.http.post(
      `${environment.api.host}${endpoints.providers}/${providerId}${endpoints.linked_stations}`,
      { departments, is_for_remove: isForRemove }
    ).pipe(
      map(
        (res: any) => res.data.linked_stations.map(ls => new Station(ls))
      ),
      catchError(err => this.errorHandlerService.handleError(err))
    );
  }

  updateAllLinkedStations(providerId: number, isForRemove: boolean): Observable<any> {
    return this.http.post(
      `${environment.api.host}${endpoints.providers}/${providerId}${endpoints.linked_stations}`, {
      update_all: true, is_for_remove: isForRemove
    }
    ).pipe(
      map(
        (res: any) => res.data.linked_stations.map(ls => new Station(ls))
      ),
      catchError(err => this.errorHandlerService.handleError(err))
    );
  }

  delete(providerId: number): Observable<any> {
    return this.http.delete(
      `${environment.api.host}${endpoints.providers}/${providerId}`
    ).pipe(
      map(
        (res: any) => res
      ),
      catchError(err => this.errorHandlerService.handleError(err))
    );
  }

  export(): Observable<Blob> {
    return this.http.post(environment.api.host + endpoints.providers + endpoints.export,
      null,
      { responseType: 'blob' }
    ).pipe(
      map(res => res),
      catchError(err => this.errorHandlerService.handleError(err))
    );
  }
}
