<div class="main-content">
    <h2 class="title-with-actions">{{'stations.title' | translate}}
        <div class="actions ml-auto">
            <button mat-flat-button color="primary" class="mr-2 mb-2" (click)="synchStationsStatus()">
                <mat-icon class="mr-1">sync</mat-icon>{{'stations.sync_station' | translate}}
            </button>
            <button mat-flat-button color="primary" class="mr-2 mb-2" (click)="gotoStationCard()">
                <mat-icon class="mr-1">add</mat-icon>{{'stations.create_station' | translate}}
            </button>
            <button mat-flat-button color="primary" class="mb-2" (click)="exportStations()">
                <mat-icon class="mr-1">download</mat-icon>{{'stations.export' | translate}}
            </button>
        </div>
    </h2>

    <mat-card>
        <div class="search-bar">
            <form class="search-form" (ngSubmit)="filterStations()" [formGroup]="searchForm" novalidate>
                <mat-form-field class="search-input mr-3">
                    <mat-label>{{'stations.search'|translate}}</mat-label>
                    <input matInput formControlName="query">
                    <span matSuffix class="d-flex">
            <mat-icon color="primary" class="pointer mr-2" (click)="clearFilter()"
              *ngIf="searchForm.controls.query.value">
              clear</mat-icon>
            <mat-icon color="primary" class="pointer" (click)="filterStations()">search</mat-icon>
          </span>
                </mat-form-field>
            </form>
        </div>
    </mat-card>

    <h3 class="mt-5">{{'stations.station_list.title' | translate}}</h3>
    <mat-card>
        <div #stationsTableContainer class="table-container mt-3">
            <table mat-table [dataSource]="stationsDataSource" class="w-100" matSort matSortDisableClear matSortActive="status" matSortDirection="asc" (matSortChange)="sortData()">
                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                    <td mat-cell *matCellDef="let row">
                        <div [class]="'station-status-bullet ' + row.status.code"></div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="brand">
                    <th mat-header-cell *matHeaderCellDef> {{'entities.stations.info.brand' | translate}} </th>
                    <td mat-cell *matCellDef="let row"> {{row.brand?.label}} </td>
                </ng-container>
                <ng-container matColumnDef="sales_channel">
                    <th mat-header-cell *matHeaderCellDef> {{'entities.stations.info.sales_channel' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let row"> {{row.sales_channel?.label}} </td>
                </ng-container>
                <ng-container matColumnDef="code_implant">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'entities.stations.code_implant' | translate}} </th>
                    <td mat-cell *matCellDef="let row"> {{row.code_implant}} </td>
                </ng-container>
                <ng-container matColumnDef="site_name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'entities.stations.info.site_name' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let row"> {{row.site_name}} </td>
                </ng-container>
                <ng-container matColumnDef="city">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'entities.stations.info.geographic_data.city' | translate}} </th>
                    <td mat-cell *matCellDef="let row"> {{row.city}} </td>
                </ng-container>
                <ng-container matColumnDef="client_name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'entities.stations.info.client_name' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let row"> {{row.client_name}} </td>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef>
                        <div class="mat-header-content">
                            {{'general.table_headers.actions' | translate}}
                        </div>
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <button mat-icon-button (click)="gotoStationCard(row.id)">
              <mat-icon color="primary">edit</mat-icon>
            </button>
                        <button mat-icon-button (click)="deleteStation(row.id)">
              <mat-icon color="primary">delete</mat-icon>
            </button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="6">
                        <ng-container *ngIf="searchForm.controls.query.value; else noDataTemplate">
                            {{'general.table_no_data_filter' | translate: {filterValue: searchForm.controls.query.value } }}
                        </ng-container>
                        <ng-template #noDataTemplate>
                            {{'general.table_no_data' | translate}}
                        </ng-template>
                    </td>
                </tr>
            </table>
        </div>
        <mat-paginator [length]="stationsPaginationItem.total" [pageSize]="25" (page)="paginationChanged($event)">
        </mat-paginator>
    </mat-card>

    <h3 class="mt-5">{{'stations.contacts' | translate}}</h3>
    <mat-card>
        <form [formGroup]="contactFunctionsFormGroup">
            <mat-form-field>
                <mat-label>{{'stations.contacts_list' | translate}}</mat-label>
                <mat-select formControlName="selected_contact_functions_ids" multiple>
                    <mat-option *ngFor="let cf of contactFunctions" [value]="cf.id">
                        {{cf.label}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div class="d-flex justify-content-end">
                <button mat-flat-button color="primary" (click)="updateSelectedContactFunctions()">{{'general.buttons.save' |
          translate}}</button>
            </div>
        </form>
    </mat-card>

    <h3 class="mt-5">{{'stations.providers' | translate}}</h3>
    <mat-card>
        <form [formGroup]="providerTypesFormGroup">
            <mat-form-field>
                <mat-label>{{'stations.providers_list' | translate}}</mat-label>
                <mat-select formControlName="selected_provider_types_ids" multiple>
                    <mat-option *ngFor="let pt of providerTypes" [value]="pt.id">
                        {{'entities.provider_types.' + pt.code | translate}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <div class="d-flex justify-content-end">
                <button mat-flat-button color="primary" (click)="updateSelectedProviderTypes()">{{'general.buttons.save' |
          translate}}</button>
            </div>
        </form>
    </mat-card>
</div>