<form [formGroup]="contactFunctionsForm" novalidate>
  <mat-card>
    <mat-card-content>
      <div class="basic-objects-list" formArrayName="contactFunctions">
        <div class="basic-object contact-function" *ngFor="let contactFunction of contactFunctionsArray.controls">
          <ng-container [formGroup]="contactFunction">
            <mat-form-field class="order-container">
              <mat-label>{{'repositories.tabs.order' | translate}}</mat-label>
              <input matInput formControlName="order" class="basic-object-input" type="number" min="0">
            </mat-form-field>
            <mat-form-field>
              <mat-label>{{'repositories.tabs.label' | translate}}</mat-label>
              <input matInput formControlName="label" class="basic-object-input">
              <div class="d-flex ml-2" matSuffix>
                <mat-icon class="pointer mr-2" color="primary" (click)="switchContactFunctionEdit(contactFunction)"
                  *ngIf="contactFunction.disabled">edit</mat-icon>
                <mat-icon class="pointer" color="primary" (click)="deleteContactFunction(contactFunction)"
                  *ngIf="contactFunction.disabled">
                  delete
                </mat-icon>
                <mat-icon class="pointer mr-2" color="primary" (click)="saveContactFunction(contactFunction)"
                  *ngIf="contactFunction.enabled">
                  check</mat-icon>
                <mat-icon class="pointer" color="primary" (click)="switchContactFunctionEdit(contactFunction)"
                  *ngIf="contactFunction.enabled">
                  clear</mat-icon>
              </div>
            </mat-form-field>
          </ng-container>
        </div>
      </div>
    </mat-card-content>
    <mat-card-actions>
      <button mat-flat-button color="primary" (click)="addContactFunction()">
        <mat-icon>add</mat-icon>{{'repositories.tabs.'+ contactFunctionType +'.add' | translate}}
      </button>
    </mat-card-actions>
  </mat-card>
</form>
