
// tslint:disable:variable-name
export class BasicCodedObject {

  id: number;
  code: string;
  is_displayed?: boolean;

  constructor(data = {}) {
    Object.assign(this, data);
  }
}
