import { Routes } from '@angular/router';
import { AppAdminGuard } from 'src/app/guards/app-admin.guard';
import { ConfigurationComponent } from './components/configuration/configuration.component';
import { ContactsComponent } from './components/contacts/contacts.component';
import { IncidentCardComponent } from './components/incidents/incident-card/incident-card.component';
import { IncidentsComponent } from './components/incidents/incidents.component';
import { MatriceCardComponent } from './components/matrices/matrice-card/matrice-card.component';
import { MatricesComponent } from './components/matrices/matrices.component';
import { ProvidersComponent } from './components/providers/providers.component';
import { RepositoriesComponent } from './components/repositories/repositories.component';
import { StationCardComponent } from './components/stations/station-card/station-card.component';
import { StationsComponent } from './components/stations/stations.component';
import { UsersComponent } from './components/users/users.component';
import { mainRoutesNames } from './main.routes.names';

export const mainRoutes: Routes = [
    {
        path: '',
        children: [
            {
                path: mainRoutesNames.INCIDENTS, children: [
                    { path: '', component: IncidentsComponent },
                    { path: ':id', component: IncidentCardComponent }
                ], data: { title: 'incidents', icon: 'live_help' }
            },
            {
                path: mainRoutesNames.STATIONS, children: [
                    { path: '', component: StationsComponent },
                    { path: ':id', component: StationCardComponent }
                ], data: { title: 'stations', icon: 'map' }
            },
            { path: mainRoutesNames.USERS, component: UsersComponent, data: { title: 'users', icon: 'people' } },
            { path: mainRoutesNames.CONTACTS, component: ContactsComponent, data: { title: 'contacts', icon: 'people' } },
            { path: mainRoutesNames.PROVIDERS, component: ProvidersComponent, data: { title: 'providers', icon: 'handyman' } },
            {
                path: mainRoutesNames.MATRICES, children: [
                    { path: '', component: MatricesComponent },
                    { path: ':id', component: MatriceCardComponent }
                ], data: { title: 'matrices', icon: 'tune' }
            },
            { path: mainRoutesNames.REPOSITORIES, component: RepositoriesComponent, data: { title: 'repositories', icon: 'room_preferences' } },
            { path: mainRoutesNames.CONFIGURATION, component: ConfigurationComponent, data: { title: 'configuration', icon: 'settings' } },
        ],
        canLoad: [AppAdminGuard],
        canActivate: [AppAdminGuard]
    }
];
