<div mat-dialog-title>
  <h3>{{(isNewContact? 'contacts.create.title' : 'contacts.update.title') | translate}}</h3>
  <mat-icon color="primary" mat-dialog-close>close</mat-icon>
</div>

<mat-dialog-content>
  <form class="create-contact-form" (ngSubmit)="submitContact(contactForm)" [formGroup]="contactForm" novalidate>
    <mat-form-field>
      <mat-label>{{'entities.contacts.name' | translate}}</mat-label>
      <input matInput formControlName="name">
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{'entities.contacts.igg' | translate}}</mat-label>
      <input matInput formControlName="igg">
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{'entities.contacts.email' | translate}}</mat-label>
      <input matInput formControlName="email">
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{'entities.contacts.cellphone' | translate}}</mat-label>
      <input matInput formControlName="cellphone">
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{'entities.contacts.contact_function' | translate}}</mat-label>
      <mat-select formControlName="function_id">
        <mat-option *ngFor="let contactFunction of contactFunctions" [value]="contactFunction.id">
          {{contactFunction.label}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{'entities.contacts.type' | translate}}</mat-label>
      <mat-select formControlName="type">
        <mat-option [value]="null">{{'general.misc.select_none' | translate}}</mat-option>
        <mat-option *ngFor="let contactType of ['COM', 'DCM']" [value]="contactType">
          {{contactType}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{'entities.contacts.registration_number' | translate}}</mat-label>
      <input matInput formControlName="registration_number">
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{'entities.contacts.department_canal' | translate}}</mat-label>
      <input matInput formControlName="department_canal">
    </mat-form-field>
    <mat-form-field>
      <mat-label>{{'entities.contacts.region' | translate}}</mat-label>
      <input matInput formControlName="region">
    </mat-form-field>
  </form>
</mat-dialog-content>

<mat-dialog-actions class="buttons-bar">
  <button mat-flat-button mat-dialog-close>{{'general.buttons.cancel' | translate}}</button>
  <button mat-flat-button color="primary" (click)="submitContact(contactForm)">
    {{'general.buttons.validate' | translate}}
  </button>
</mat-dialog-actions>
