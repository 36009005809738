<div class="container-fluid">
  <div class="row no-gutters">
    <div class="d-flex user-info justify-content-center flex-column col-12 mb-4" [formGroup]="getUserInfoForm()">
      <div class="row">
        <h2 class="col-12 pb-3">{{'my_account.title' | translate}}</h2>
        <mat-form-field class="col-12 col-lg-4">
          <mat-label>{{'entities.users.first_name' | translate}}</mat-label>
          <input type="text" matInput formControlName="first_name" />
        </mat-form-field>
        <mat-form-field class="col-12 col-lg-4">
          <input type="text" matInput formControlName="last_name" />
        </mat-form-field>
        <mat-form-field class="col-12 col-lg-4">
          <mat-label>{{'entities.users.email' | translate}}</mat-label>
          <input type="email" matInput formControlName="email" />
        </mat-form-field>
        <mat-form-field class="col-12 col-lg-4">
          <mat-label>{{'entities.users.igg' | translate}}</mat-label>
          <input type="text" matInput formControlName="igg" maxlength="8" />
        </mat-form-field>
        <div class="col-12 d-flex justify-content-end pt-3">
          <button mat-raised-button color="primary" (click)="saveUserInfo()" [disabled]="getUserInfoForm().invalid">
            Update my account
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
