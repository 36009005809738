<div mat-dialog-title>
  <h3>{{'repositories.tabs.delete_confirm.title' | translate}}
  </h3>
  <mat-icon color="primary" mat-dialog-close>close</mat-icon>
</div>

<p>{{'repositories.tabs.delete_confirm.text' | translate: {label: (objectData.label ? objectData.label : objectData.code) } }}</p>

<div class="buttons-bar">
  <button mat-flat-button type="button" class="mr-3" mat-dialog-close>
    <span class="btn-text">
      {{'general.buttons.cancel' | translate}}
    </span>
  </button>
  <button mat-flat-button color="primary" (click)="confirmDeletion()">
    <span class="btn-text">
      {{'general.buttons.validate' | translate}}
    </span>
  </button>
</div>
