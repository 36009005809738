import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { BasicLabelledObject } from 'src/app/models/basic-labelled-object.model';
import { ConfirmDeleteObjectDialogComponent } from '../dialogs/confirm-delete-object-dialog/confirm-delete-object-dialog.component';
import { BasicObjectService } from './../../../services/basic-object.service';

@Component({
  selector: 'app-contact-functions-tab',
  templateUrl: './contact-functions-tab.component.html',
  styleUrls: ['./contact-functions-tab.component.scss']
})
export class ContactFunctionsTabComponent implements OnInit {

  readonly contactFunctionType = 'contact_functions';

  contactFunctionsForm: FormGroup;

  constructor(
    private basicObjectService: BasicObjectService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private translate: TranslateService
  ) {
  }

  get contactFunctionsArray(): FormArray {
    return this.contactFunctionsForm.get('contactFunctions') as FormArray;
  }

  ngOnInit(): void {
    this.initForm();

    this.basicObjectService.getAll(this.contactFunctionType).subscribe((contactFunctions: BasicLabelledObject[]) => {
      this.initForm(contactFunctions);
    });
  }

  switchContactFunctionEdit(contactFunction: FormGroup): void {
    if (contactFunction.enabled) {
      contactFunction.controls.label.reset(contactFunction.controls.initialLabel.value);
      contactFunction.controls.description.reset(contactFunction.controls.initialDescription.value);
      contactFunction.disable();
      return;
    };

    contactFunction.enable();
  }

  saveContactFunction(contactFunction: FormGroup): void {
    const id = contactFunction.value.id;
    const label = contactFunction.value.label;

    const httpRequest = id ? this.basicObjectService.update(this.contactFunctionType, id, contactFunction.value)
      : this.basicObjectService.create(this.contactFunctionType, contactFunction.value);

    httpRequest.subscribe(() => {
      contactFunction.disable();
      contactFunction.controls.initialLogo.setValue(contactFunction.controls.logo.value);
      contactFunction.controls.logo.setValue(null);
      this.snackBar.open(this.translate.instant('general.messages.data_saved'));
    });
  }

  deleteContactFunction(contactFunction: FormGroup): void {
    if (!contactFunction.controls.id.value) {
      this.removeContactFunction(contactFunction);
      return;
    }

    const dialogRef = this.dialog.open(ConfirmDeleteObjectDialogComponent, {
      data: contactFunction.value
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res?.confirm) {
        this.basicObjectService.delete(this.contactFunctionType, contactFunction.controls.id.value).subscribe(() => {
          this.removeContactFunction(contactFunction);
          this.snackBar.open(this.translate.instant('general.messages.data_deleted'));
        });
      }
    });
  }

  removeContactFunction(contactFunction: FormGroup): void {
    this.contactFunctionsArray.removeAt(this.contactFunctionsArray.controls.indexOf(contactFunction));
  }

  addContactFunction(contactFunction?: BasicLabelledObject): void {
    const contactFunctionGroup = this.fb.group({
      id: contactFunction?.id,
      order: [contactFunction?.order, [Validators.min(0)]],
      initialLabel: contactFunction?.label,
      initialOrder: contactFunction?.order,
      label: [contactFunction?.label, Validators.required],
    });

    contactFunctionGroup.disable();
    this.contactFunctionsArray.push(contactFunctionGroup);
  }

  private initForm(contactFunctions?: BasicLabelledObject[]): void {
    this.contactFunctionsForm = this.fb.group({
      contactFunctions: this.fb.array([])
    });

    if (contactFunctions) {
      this.initContactFunctionsFormArray(contactFunctions);
    }
  }

  private initContactFunctionsFormArray(contactFunctions: BasicLabelledObject[]): void {
    contactFunctions.forEach((contactFunction: BasicLabelledObject) => {
      this.addContactFunction(contactFunction);
    });
  }
}
