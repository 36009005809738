import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { BasicLabelledObject } from 'src/app/models/basic-labelled-object.model';
import { ConfirmDeleteObjectDialogComponent } from '../dialogs/confirm-delete-object-dialog/confirm-delete-object-dialog.component';
import { BasicObjectService } from './../../../services/basic-object.service';

@Component({
  selector: 'app-basic-objects-tab',
  templateUrl: './basic-objects-tab.component.html',
  styleUrls: ['./basic-objects-tab.component.scss']
})
export class BasicObjectsTabComponent implements OnInit {

  @Input() basicObjectType: string;
  @Input() withDescription: boolean;
  @Input() withLogo: boolean;

  basicObjectsForm: FormGroup;

  constructor(
    private basicObjectService: BasicObjectService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private translate: TranslateService
  ) {
  }

  get basicObjectsArray(): FormArray {
    return this.basicObjectsForm.get('basicObjects') as FormArray;
  }

  ngOnInit(): void {
    this.initForm();

    this.basicObjectService.getAll(this.basicObjectType).subscribe((basicObjects: BasicLabelledObject[]) => {
      this.initForm(basicObjects);
    });
  }

  switchBasicObjectEdit(basicObject: FormGroup): void {
    if (basicObject.enabled) {
      basicObject.controls.label.reset(basicObject.controls.initialLabel.value);
      basicObject.controls.description.reset(basicObject.controls.initialDescription.value);
      basicObject.disable();
      return;
    };

    basicObject.enable();
  }

  saveBasicObject(basicObject: FormGroup): void {
    const id = basicObject.value.id;
    const label = basicObject.value.label;

    const httpRequest = id ? this.basicObjectService.update(this.basicObjectType, id, basicObject.value)
      : this.basicObjectService.create(this.basicObjectType, basicObject.value);

    httpRequest.subscribe(() => {
      basicObject.disable();
      if (basicObject.controls.initialLogo) {
        basicObject.controls.initialLogo.setValue(basicObject.controls.logo.value);
        basicObject.controls.logo.setValue(null);
      }
      this.snackBar.open(this.translate.instant('general.messages.data_saved'));
    });
  }

  deleteBasicObject(basicObject: FormGroup): void {
    if (!basicObject.controls.id.value) {
      this.removeBasicObject(basicObject);
      return;
    }

    const dialogRef = this.dialog.open(ConfirmDeleteObjectDialogComponent, {
      data: basicObject.value
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res?.confirm) {
        this.basicObjectService.delete(this.basicObjectType, basicObject.controls.id.value).subscribe(() => {
          this.removeBasicObject(basicObject);
          this.snackBar.open(this.translate.instant('general.messages.data_deleted'));
        });
      }
    });
  }

  removeBasicObject(basicObject: FormGroup): void {
    this.basicObjectsArray.removeAt(this.basicObjectsArray.controls.indexOf(basicObject));
  }

  addBasicObject(basicObject?: BasicLabelledObject): void {
    const basicObjectGroup = this.fb.group({
      id: basicObject?.id,
      initialLabel: basicObject?.label,
      initialDescription: basicObject?.description,
      label: [basicObject?.label, Validators.required],
      description: [basicObject?.description],
      logo: [null]
    });

    if (this.withLogo) {
      basicObjectGroup.addControl('initialLogo', this.fb.control(basicObject?.logo));
    }

    basicObjectGroup.disable();
    this.basicObjectsArray.push(basicObjectGroup);
  }

  handleLogoInput(files: FileList, basicObject: FormGroup): void {
    const selectedFile = files.item(0);
    const logoCtrl = basicObject.controls.logo;

    if (selectedFile.size > 50000) {
      logoCtrl.setErrors({ tooLarge: true });
      return;
    }
    logoCtrl.setErrors({ tooLarge: null });

    const reader = new FileReader();

    reader.addEventListener("load", function () {
      // convert image file to base64 string
      logoCtrl.setValue(reader.result);
    }, false);
    reader.readAsDataURL(selectedFile);
  }

  clearChoosenLogo(basicObjectGroup: FormGroup): void {
    basicObjectGroup.controls.logo.setValue(null);
  }

  private initForm(basicObjects?: BasicLabelledObject[]): void {
    this.basicObjectsForm = this.fb.group({
      basicObjects: this.fb.array([])
    });

    if (basicObjects) {
      this.initBasicObjectsFormArray(basicObjects);
    }
  }

  private initBasicObjectsFormArray(basicObjects: BasicLabelledObject[]): void {
    basicObjects.forEach((basicObject: BasicLabelledObject) => {
      this.addBasicObject(basicObject);
    });
  }
}
