import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Station } from 'src/app/models/station.model';
import { endpoints } from 'src/environments/endpoints';
import { environment } from 'src/environments/environment';
import { ErrorHandlerService } from '../../shared/services/error-handler.service';
import { Contact } from './../../../models/contact.model';


@Injectable({
  providedIn: 'root'
})
export class ContactService {
  constructor(
    private http: HttpClient,
    private errorHandlerService: ErrorHandlerService
  ) { }

  getAll(): Observable<Contact[]> {
    return this.http.get(
      `${environment.api.host}${endpoints.contacts}`
    ).pipe(
      map(
        (res: any) => res.data.contacts.map(contact => new Contact(contact))
      ),
      catchError(err => this.errorHandlerService.handleError(err))
    );
  }

  getLinkedStations(contactId: number): Observable<Station[]> {
    return this.http.get(
      `${environment.api.host}${endpoints.contacts}/${contactId}${endpoints.linked_stations}`
    ).pipe(
      map(
        (res: any) => res.data.linked_stations.map(ls => new Station(ls))
      ),
      catchError(err => this.errorHandlerService.handleError(err))
    );
  }

  create(contactFormValue: any): Observable<any> {
    return this.http.post(
      `${environment.api.host}${endpoints.contacts}`, contactFormValue
    ).pipe(
      map(
        (res: any) => res
      ),
      catchError(err => this.errorHandlerService.handleError(err))
    );
  }

  update(contactData: Contact): Observable<any> {
    return this.http.patch(
      `${environment.api.host}${endpoints.contacts}/${contactData.id}`, contactData
    ).pipe(
      map(
        (res: any) => res
      ),
      catchError(err => this.errorHandlerService.handleError(err))
    );
  }

  updateLinkedStations(contactId: number, linkedStationsIds: number[]): Observable<any> {
    return this.http.post(
      `${environment.api.host}${endpoints.contacts}/${contactId}${endpoints.linked_stations}`,
      { stations_ids: linkedStationsIds }
    ).pipe(
      map(
        (res: any) => res.data.linked_stations.map(ls => new Station(ls))
      ),
      catchError(err => this.errorHandlerService.handleError(err))
    );
  }

  updateLinkedStationsByDepartments(contactId: number, departments: string[], salesChannel: string, isForRemove: boolean): Observable<any> {
    return this.http.post(
      `${environment.api.host}${endpoints.contacts}/${contactId}${endpoints.linked_stations}`,
      { departments, salesChannel, is_for_remove: isForRemove }
    ).pipe(
      map(
        (res: any) => res.data.linked_stations.map(ls => new Station(ls))
      ),
      catchError(err => this.errorHandlerService.handleError(err))
    );
  }

  updateAllLinkedStations(contactId: number, isForRemove: boolean): Observable<any> {
    return this.http.post(
      `${environment.api.host}${endpoints.contacts}/${contactId}${endpoints.linked_stations}`, {
      update_all: true, is_for_remove: isForRemove
    }
    ).pipe(
      map(
        (res: any) => res.data.linked_stations.map(ls => new Station(ls))
      ),
      catchError(err => this.errorHandlerService.handleError(err))
    );
  }

  delete(contactId: number): Observable<any> {
    return this.http.delete(
      `${environment.api.host}${endpoints.contacts}/${contactId}`
    ).pipe(
      map(
        (res: any) => res
      ),
      catchError(err => this.errorHandlerService.handleError(err))
    );
  }

  export(): Observable<Blob> {
    return this.http.post(environment.api.host + endpoints.contacts + endpoints.export,
      null,
      { responseType: 'blob' }
    ).pipe(
      map(res => res),
      catchError(err => this.errorHandlerService.handleError(err))
    );
  }
}
