import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { endpoints } from 'src/environments/endpoints';
import { BasicCodedObject } from '../../../models/basic-coded-object.model';
import { environment } from './../../../../environments/environment';
import { User } from './../../../models/user.model';
import { ErrorHandlerService } from './../../shared/services/error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  roles: BasicCodedObject[];

  constructor(
    private http: HttpClient,
    private errorHandlerService: ErrorHandlerService
  ) { }

  getAll(): Observable<User[]> {
    return this.http.get(
      `${environment.api.host}${endpoints.users}`
    ).pipe(
      map(
        (res: any) => res.data.users.map(user => new User(user))
      ),
      catchError(err => this.errorHandlerService.handleError(err))
    );
  }

  create(userFormValue: any): Observable<any> {
    return this.http.post(
      `${environment.api.host}${endpoints.users}`, userFormValue
    ).pipe(
      map(
        (res: any) => res
      ),
      catchError(err => this.errorHandlerService.handleError(err))
    );
  }

  update(userData: User): Observable<any> {
    return this.http.patch(
      `${environment.api.host}${endpoints.users}/${userData.id}`, userData
    ).pipe(
      map(
        (res: any) => res
      ),
      catchError(err => this.errorHandlerService.handleError(err))
    );
  }

  delete(userId: number): Observable<any> {
    return this.http.delete(
      `${environment.api.host}${endpoints.users}/${userId}`
    ).pipe(
      map(
        (res: any) => res
      ),
      catchError(err => this.errorHandlerService.handleError(err))
    );
  }

  getRoles(): Observable<BasicCodedObject[]> {
    if (this.roles) { return of(this.roles); }

    return this.http.get(
      `${environment.api.host}${endpoints.roles}`
    ).pipe(
      map(
        (res: any) => {
          this.roles = res.data.roles.map(role => new BasicCodedObject(role));
          return this.roles;
        }
      ),
      catchError(err => this.errorHandlerService.handleError(err))
    );
  }

  export(): Observable<Blob> {
    return this.http.post(environment.api.host + endpoints.users + endpoints.export,
      null,
      { responseType: 'blob' }
    ).pipe(
      map(res => res),
      catchError(err => this.errorHandlerService.handleError(err))
    );
  }
}
